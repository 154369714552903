import React, { useState, useEffect, useRef, useMemo, Suspense } from "react";
// { useEffect }

import styles from "./TableCard.module.scss";
import classNames from "classnames/bind";
import Tooltip from "../atoms/Tooltip";
import List from "../atoms/List";
import Card from "../atoms/Card";
import Divider from "../atoms/Divider";
import Input from "./Input";
import SummaryCard from "./SummaryCard";
import DataCard from "./DataCard";
import Button from "../atoms/Button";
import { _transformScroll } from "../util/alias";
import useGlobalVar from "../hooks/useGlobalVar";
import { encodeNumberList } from "../util/encodeData";
import { SERVICE_URI } from "../shortcut";
import useGlobalData from "../hooks/useGlobalData";
import Chip from "../atoms/Chip";
import LoadedImage from "../atoms/LoadedImage";
import { color, motion } from "framer-motion";
import { ReactComponent as Icon2D } from "../svgs/Icon2D.svg";
import { ReactComponent as IconMaterial } from "../svgs/IconMaterial.svg";
import { ReactComponent as Icon3D } from "../svgs/Icon3D.svg";
import LikeAutomatedById from "./LikeAutomatedById";

const cx = classNames.bind(styles);

const TableCard = ({
  id,
  collection = "brand",
  src = "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  color_src_list = [
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  ],
  type,
  title = "title",
  like = false,
  like_num = 0,
  illust = "illust",
  illust2 = "illust2",
  onClick,
  clicked,
  clickable = true,
  no_image,
  show_like = true,
  onMouseEnter,
  onMouseLeave,
  sensitive,
}) => {
  return (
    <div
      className={cx(
        "wrapper",
        type,
        clicked ? "clicked" : "",
        no_image ? "no-image" : "",
        clickable ? "clickable" : "disable"
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <List type="row" gap={type == "small" ? 0 : 0.25}>
        {no_image ? (
          <></>
        ) : (
          <div className={cx("frame-image", type)}>
            <Suspense>
              <LoadedImage
                className={cx("image", type)}
                src={src}
                width={type == "small" ? "24px" : "52px"}
                height={type == "small" ? "24px" : "52px"}
                onError={(e) => (e.currentTarget.src = "/img/icon_noimage.png")}
                duration={0.3}
                delay={0.3}
              ></LoadedImage>
            </Suspense>
          </div>
        )}
        <div className={cx("frame-content", type)}>
          <List
            type="row"
            align="center"
            attach="space"
            tight={false}
            style={{ overflow: "hidden" }}
          >
            <div className={cx("frame-text", type)}>
              <List align="left">
                <div className={cx("text-title", type)}>{title}</div>
                <div className={cx("text-illust", type)}>{illust}</div>
                <div className={cx("text-illust", type)}>{illust2}</div>
              </List>
            </div>
            {show_like && (
              <LikeAutomatedById
                like_num={like_num}
                collection={collection}
                id={id}
                show_num={type !== "small"}
                sensitive={sensitive}
              />
            )}
          </List>
        </div>
      </List>
    </div>
  );
};

export default TableCard;

// ### Card

// - shape: default / rectangle
// - children: any
// - padding: int
// - clickable: boolean
// - transparent: boolean
// - onClick: ()=>any
// - use_tooltip: boolean
// - tooltip: [any]
// - tight: boolean
