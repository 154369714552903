import React, {
  useEffect,
  useReducer,
  useState,
  Suspense,
  useRef,
  useMemo,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./MaterialBoardPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";
import Button from "../atoms/Button";
import Like from "../component/Like";
import Chip from "../atoms/Chip";
import Switch from "../atoms/Switch";
import LoadedImage from "../atoms/LoadedImage";
import { motion } from "framer-motion";
import TableCard from "../component/TableCard";
import Search from "../atoms/Search";
import MaterialBoard from "../component/MaterialBoard";
import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import { ReactComponent as IconDropDown } from "../svgs/IconDropDown.svg";
import axios from "axios";
import { API_URI } from "../shortcut";
import {
  convertMaterialDataForMaterialBoardPage,
  getMaterialBoardPageQueryOfMaterialData,
} from "../functions/handleMaterialData";
import useQueryParam from "../hooks/useQueryParam";
import CustomLink from "../component/CustomLink";
import {
  convertBrandDataForMaterialBoardPage,
  getMaterialBoardPageQueryOfBrandData,
} from "../functions/handleBrandData";
import AlertPopupScienario from "../component/AlertPopupScienario";
import {
  convertArchitectureDataForMaterialBoardPage,
  getMaterialBoardPageQueryOfArchitectureData,
} from "../functions/handleArchitectureData";
import { ipad_width } from "../util/style";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const materialboard_sample_list = [
  {
    id: "sample_1",
    table_data: {
      src: "/img/materialboard_sample/sample_1.png",
      title: "화장실 샘플",
      illust: "24.03.12. 생성 · 24.06.24. 수정",
      illust2: "화장실 머티리얼보드 샘플입니다.",
    },
    board_list: [
      {
        type: "타일",
        src: "/img/materialboard_sample/sample_1_1.jpg",
        initial: { x: 0, y: 0, width: 450, height: 400 },
        zIndex: 1,
      },
      {
        type: "타일",
        src: "/img/materialboard_sample/sample_1_2.jpg",
        initial: { x: -50, y: 175, width: 150, height: 150 },
        zIndex: 2,
      },
      {
        type: "수전·도기",
        src: "/img/materialboard_sample/sample_1_3.webp",
        initial: { x: 175, y: 50, width: 150, height: 200 },
        zIndex: 3,
      },
      {
        type: "타일",
        src: "/img/materialboard_sample/sample_1_4.avif",
        initial: { x: -100, y: -50, width: 300, height: 150 },
        zIndex: 1,
      },
    ],
  },
  {
    id: "sample_2",
    table_data: {
      src: "/img/materialboard_sample/sample_2.png",
      title: "옥외 테라스 샘플",
      illust: "24.06.03. 생성 · 24.06.17. 수정",
      illust2: "옥외 테라스 머티리얼보드 샘플입니다.",
    },
    board_list: [
      {
        type: "마루",
        src: "/img/materialboard_sample/sample_2_1.jpg",
        initial: { x: 25, y: 70, width: 300, height: 400 },
        zIndex: 1,
      },
      {
        type: "금속",
        src: "/img/materialboard_sample/sample_2_2.jpg",
        initial: { x: -120, y: -20, width: 280, height: 500 },
        zIndex: 2,
      },
      {
        type: "벽돌",
        src: "/img/materialboard_sample/sample_2_3.jpg",
        initial: { x: 190, y: 50, width: 150, height: 200 },
        zIndex: 3,
      },
    ],
  },
  {
    id: "sample_3",
    table_data: {
      src: "/img/materialboard_sample/sample_3.png",
      title: "주방 샘플",
      illust: "24.05.12. 생성 · 24.05.17. 수정",
      illust2: "주방 머티리얼보드 샘플입니다.",
    },
    board_list: [
      {
        type: "페인트",
        src: "/img/materialboard_sample/sample_3_1.jpg",
        initial: { x: -115, y: 15, width: 325, height: 515 },
        zIndex: 1,
      },
      {
        type: "목재",
        src: "/img/materialboard_sample/sample_3_2.jpg",
        initial: { x: -10, y: -110, width: 370, height: 200 },
        zIndex: 2,
      },
      {
        type: "타일",
        src: "/img/materialboard_sample/sample_3_3.webp",
        initial: { x: 90, y: 80, width: 330, height: 330 },
        zIndex: 3,
      },
      {
        type: "금속",
        src: "/img/materialboard_sample/sample_3_4.jpg",
        initial: { x: -125, y: 115, width: 210, height: 210 },
        zIndex: 4,
      },
      {
        type: "금속",
        src: "/img/materialboard_sample/sample_3_5.jpg",
        initial: { x: 175, y: -100, width: 100, height: 100 },
        zIndex: 5,
      },
      {
        type: "페인트",
        src: "/img/materialboard_sample/sample_3_6.png",
        initial: { x: 210, y: 150, width: 150, height: 75 },
        zIndex: 5,
      },
    ],
  },
];

const MaterialBoardPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [close_data, setCloseData] = useState({ a: false });
  const [material_like_list, setMaterialLikeList] = useState([]);
  const [brand_like_list, setBrandLikeList] = useState([]);
  const [architecture_like_list, setArchitectureLikeList] = useState([]);
  const [materialboard_list, setMaterialboardList] = useState([]);
  const [alert_popup_data, setAlertPopupData] = useState({});
  const [materialboard_sample_id, setMaterialBoardSampleId] = useState(
    materialboard_sample_list[0].id
  );
  const materialboard_sample_data = useMemo(
    () =>
      materialboard_sample_list.filter(
        (e) => e.id === materialboard_sample_id
      )?.[0],
    [materialboard_sample_id]
  );
  // const [data_loaded, setDataLoaded] = useState(false);

  const bound_ref = useRef();
  const query = useQueryParam();

  useEffect(() => {
    axios
      .put(
        API_URI + "data",
        getMaterialBoardPageQueryOfMaterialData(
          global_data?.like_data?.material ?? [],
          match?.params?.type === "like" ? global_data.filter?.current : [],
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) => {
        setMaterialLikeList(convertMaterialDataForMaterialBoardPage(res.data));
      })
      .catch(console.log);
    if (match?.params?.type === "like") {
      axios
        .put(
          API_URI + "data",
          getMaterialBoardPageQueryOfBrandData(
            global_data?.like_data?.brand ?? [],
            global_var.data_sort,
            global_data.search_data?.filter?.current ||
              (query.get("search") ?? "")
          )
        )
        .then((res) => {
          setBrandLikeList(convertBrandDataForMaterialBoardPage(res.data));
        })
        .catch(console.log);
      axios
        .put(
          API_URI + "data",
          getMaterialBoardPageQueryOfArchitectureData(
            global_data?.like_data?.architecture ?? [],
            global_var.data_sort
          )
        )
        .then((res) =>
          setArchitectureLikeList(
            convertArchitectureDataForMaterialBoardPage(res.data)
          )
        )
        .catch(console.log);
    }
  }, [
    global_data.filter,
    global_var.data_sort,
    global_data.search_data,
    match?.params?.type,
  ]);

  return (
    <>
      <MetaData
        path={
          match?.params?.type
            ? "/materialboard/" + match?.params?.type
            : "/materialboard"
        }
      />
      <Header emph="materialboard" />
      <div className={cx("wrapper", match?.params?.type)}>
        {/* <LoadedImage
          className={cx("background")}
          src="https://t4.ftcdn.net/jpg/02/20/66/39/240_F_220663994_qXlGkI4TV9WkPbraM0tjcTNrVcyLWF9D.jpg"
          duration={0.1}
        ></LoadedImage> */}

        <List gap={0} tight={false}>
          <div
            className={cx(
              "body",
              match?.params?.type !== "like" ? "scrollable" : ""
            )}
          >
            <List tight={false}>
              <div className={cx("frame-title")}>
                <List
                  type={global_var.window_width < ipad_width ? "column" : "row"}
                  align={
                    global_var.window_width < ipad_width ? "left" : "center"
                  }
                  attach="space"
                  gap={1}
                >
                  <List gap={0} align="left">
                    <div className={cx("text-title")}>머티리얼보드</div>
                    <div className={cx("text-illust")}>
                      조합하고 비교해봐요.
                    </div>
                  </List>
                  <List type="row" align="center" gap={1}>
                    <Switch
                      color="green-light"
                      list={[
                        { kor: "내 좋아요 목록", eng: "like" },
                        { kor: "보드 만들기", eng: "board" },
                      ]}
                      to={(e) => "/materialboard/" + e.eng}
                      default_emph_idx={match?.params?.type == "like" ? 0 : 1}
                    ></Switch>
                  </List>
                </List>
              </div>

              {match?.params?.type !== "like" ? (
                <div className={cx("frame-content")}>
                  <List
                    type={
                      global_var.window_width < ipad_width ? "column" : "row"
                    }
                    gap={1}
                    tight={false}
                    fillY
                  >
                    <motion.div
                      className={cx("frame-image")}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 1 }}
                      transition={{
                        delay: 0,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                      ref={bound_ref}
                    >
                      <List
                        align="center"
                        attach="center"
                        tight={false}
                        style={
                          global_var.window_width < ipad_width &&
                          global_var.media_mobile
                            ? {
                                transform:
                                  "scale(" +
                                  global_var.window_width / 720 +
                                  ")",
                              }
                            : {}
                        }
                      >
                        <Suspense
                          fallback={
                            <motion.div
                              className={cx("frame-loading")}
                              initial={{ opacity: 0.3 }}
                              animate={{ opacity: 0.7 }}
                              transition={{
                                repeat: Infinity,
                                repeatType: "reverse",
                                ease: "easeInOut",
                                duration: 0.8,
                              }}
                            ></motion.div>
                          }
                        >
                          {(materialboard_sample_data?.board_list ?? []).map(
                            (data, idx) => (
                              <MaterialBoard
                                key={idx}
                                {...data}
                                bound_ref={bound_ref}
                              />
                            )
                          )}
                        </Suspense>
                      </List>

                      <div className={cx("frame-image-overlay")}>
                        {!global_var.media_mobile ? (
                          <List
                            type="row"
                            padding={0.5}
                            gap={0.5}
                            attach="end"
                            align="left"
                            tight={false}
                            multiple_line
                          >
                            <Button
                              color="green-light"
                              onClick={() => {
                                setAlertPopupData({
                                  data: "보드 내 유형 추가",
                                });
                              }}
                              GTM={"MaterialBoardPage_유형_추가"}
                            >
                              + 보드 안에 유형 추가하기
                            </Button>
                            <Button
                              color="green-light"
                              onClick={() => {
                                setAlertPopupData({
                                  data: "머티리얼보드 저장",
                                });
                              }}
                              GTM={"MaterialBoardPage_저장"}
                            >
                              저장하기
                            </Button>
                            <Button
                              color="brown-light"
                              onClick={() => {
                                setAlertPopupData({
                                  data: "새 머티리얼보드 저장",
                                });
                              }}
                              GTM={"MaterialBoardPage_새_저장"}
                            >
                              새 머티리얼 보드로 저장하기
                            </Button>
                          </List>
                        ) : (
                          <div className={cx("text-alert", "small")}>
                            배치를 바꾸거나 크기를 조절하고 싶으시다면
                            데스크탑에서 편집이 가능해요.
                          </div>
                        )}
                      </div>
                    </motion.div>
                    <div className={cx("frame-info")}>
                      <List gap={1} tight={false}>
                        <motion.div
                          className={cx("frame-div")}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 1 }}
                          transition={{
                            delay: 0.1,
                            duration: 0.5,
                            type: "spring",
                            stiffness: 100,
                          }}
                        >
                          <List padding={0.5} gap={0.5} tight={false}>
                            <List
                              type="row"
                              align="end"
                              attach="space"
                              tight={false}
                            >
                              <div className={cx("text-section")}>보드</div>
                              <Search
                                id="materialboard"
                                placeholder="보드 검색하기"
                              />
                            </List>
                            <div className={cx("frame-scroll")}>
                              <List gap={0.25} tight={false}>
                                {materialboard_sample_list.map((data, idx) => (
                                  <TableCard
                                    key={idx}
                                    {...data.table_data}
                                    show_like={false}
                                    clickable
                                    clicked={
                                      data.id === materialboard_sample_id
                                    }
                                    onClick={() =>
                                      setMaterialBoardSampleId(data.id)
                                    }
                                  />
                                ))}
                              </List>
                            </div>
                          </List>
                        </motion.div>
                        {global_var.window_width >= ipad_width && (
                          <motion.div
                            className={cx("frame-div")}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 1 }}
                            transition={{
                              delay: 0.2,
                              duration: 0.5,
                              type: "spring",
                              stiffness: 100,
                            }}
                          >
                            <List padding={0.5} gap={0.5} tight={false}>
                              <div className={cx("text-section")}>
                                좋아하는 건축자재
                              </div>
                              <div className={cx("frame-scroll")}>
                                <List
                                  padding={0.25}
                                  gap={0.5}
                                  align="left"
                                  tight={false}
                                >
                                  {(materialboard_sample_data.board_list ?? [])
                                    .map((board_data) => board_data.type)
                                    .map((key, idx) => (
                                      <List
                                        gap={0.25}
                                        key={idx}
                                        align="left"
                                        tight={false}
                                        style={{ height: "none" }}
                                      >
                                        <div
                                          className={cx("frame-subsection")}
                                          onClick={() => {
                                            setCloseData((e) => {
                                              const new_e = { ...e };
                                              new_e[key + idx] = !(
                                                e[key + idx] ?? false
                                              );
                                              return new_e;
                                            });
                                          }}
                                        >
                                          <List
                                            type="row"
                                            attach="space"
                                            tight={false}
                                          >
                                            <div
                                              className={cx("text-subsection")}
                                            >
                                              {idx + 1}. {key}
                                            </div>
                                            <div
                                              className={cx(
                                                "frame-icon",
                                                close_data[key + idx]
                                                  ? ""
                                                  : "close"
                                              )}
                                            >
                                              <IconDropDown />
                                            </div>
                                          </List>
                                        </div>
                                        <motion.div
                                          className={cx("frame-dropdown")}
                                          animate={{
                                            height: close_data[key + idx]
                                              ? 0
                                              : "100%",
                                            opacity: close_data[key + idx]
                                              ? 0
                                              : 1,
                                          }}
                                        >
                                          <List
                                            type="row"
                                            gap={0.25}
                                            tight={false}
                                            multiple_line
                                          >
                                            {material_like_list
                                              .filter(
                                                (e) => e.filter?.type === key
                                              )
                                              .map((data, idx2) => (
                                                <TableCard
                                                  key={data.id + idx2}
                                                  {...data}
                                                  type="small"
                                                  show_like={false}
                                                  onClick={() =>
                                                    setAlertPopupData({
                                                      data: "머티리얼보드 수정",
                                                    })
                                                  }
                                                />
                                              ))}
                                          </List>
                                          {material_like_list.filter(
                                            (e) => e.filter?.type === key
                                          ).length === 0 && (
                                            <motion.div
                                              className={cx("text-error")}
                                              initial={{ opacity: 0.3 }}
                                              animate={{ opacity: 0.7 }}
                                              transition={{
                                                repeat: Infinity,
                                                repeatType: "reverse",
                                                ease: "easeInOut",
                                                duration: 1.5,
                                              }}
                                            >
                                              <div>
                                                좋아하는 건축자재가 없어요.
                                              </div>
                                              <div>
                                                건축자재 탭에서 좋아하는 자재를
                                                추가해봐요.
                                              </div>
                                            </motion.div>
                                          )}
                                        </motion.div>
                                      </List>
                                    ))}
                                </List>
                              </div>
                            </List>
                          </motion.div>
                        )}
                      </List>
                    </div>
                  </List>
                </div>
              ) : (
                <>
                  <Filter
                    show_properties={["type"]}
                    show_dropdown={["sort"]}
                    onSearch={(e) => {
                      window.history.replaceState(
                        {},
                        "",
                        "/materialboard/like?search=" + e
                      );
                    }}
                  />
                  <ScrollContainer
                    horizontal
                    vertical={false}
                    className={cx("frame-content2")}
                    hideScrollbars={false}
                  >
                    <List type="row" align="left" gap={1} tight={false} fillY>
                      <div className={cx("frame-margin2")}></div>
                      <motion.div
                        className={cx("frame-div2")}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 1 }}
                        transition={{
                          delay: 0,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <List
                          gap={0.5}
                          tight={false}
                          style={{ height: "auto" }}
                        >
                          <div className={cx("text-section")}>건축자재</div>
                          <div className={cx("frame-scroll2")}>
                            <List
                              padding={0.25}
                              gap={0.5}
                              align="left"
                              tight={false}
                            >
                              {[
                                ...new Set(
                                  material_like_list.reduce(
                                    (prev, curr) => [
                                      ...prev,
                                      curr?.filter?.type,
                                    ],
                                    []
                                  )
                                ),
                              ].map((key, idx) => (
                                <List
                                  gap={0.25}
                                  key={idx}
                                  align="left"
                                  tight={false}
                                  style={{ height: "none" }}
                                >
                                  <div
                                    className={cx("frame-subsection")}
                                    onClick={() => {
                                      setCloseData((e) => {
                                        const new_e = { ...e };
                                        new_e[key] = !(e[key] ?? false);
                                        return new_e;
                                      });
                                    }}
                                  >
                                    <List
                                      type="row"
                                      attach="space"
                                      tight={false}
                                    >
                                      <div className={cx("text-subsection")}>
                                        {key}
                                      </div>
                                      <div
                                        className={cx(
                                          "frame-icon",
                                          close_data[key] ? "" : "close"
                                        )}
                                      >
                                        <IconDropDown />
                                      </div>
                                    </List>
                                  </div>
                                  <motion.div
                                    className={cx("frame-dropdown")}
                                    animate={{
                                      height: close_data[key] ? 0 : "100%",
                                      opacity: close_data[key] ? 0 : 1,
                                    }}
                                  >
                                    <List
                                      type="row"
                                      gap={0.25}
                                      tight={false}
                                      multiple_line
                                    >
                                      {material_like_list
                                        .filter((e) => e.filter?.type === key)
                                        .map((data, idx) => (
                                          <CustomLink
                                            key={data.id + idx}
                                            to={"/material/" + data.id}
                                            style={{ maxWidth: "100%" }}
                                          >
                                            <TableCard
                                              {...data}
                                              type="small"
                                              show_like
                                              id={data.id}
                                              collection="material"
                                            />
                                          </CustomLink>
                                        ))}
                                    </List>
                                  </motion.div>
                                </List>
                              ))}
                              {material_like_list.length === 0 && (
                                <motion.div
                                  className={cx("text-error")}
                                  initial={{ opacity: 0.3 }}
                                  animate={{ opacity: 0.7 }}
                                  transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut",
                                    duration: 1.5,
                                  }}
                                >
                                  <div>좋아하는 건축자재가 없어요.</div>
                                  <div>검색 필터를 바꾸어보거나</div>
                                  <div>좋아하는 자재를 추가해봐요.</div>
                                </motion.div>
                              )}
                            </List>
                          </div>
                        </List>
                      </motion.div>
                      <motion.div
                        className={cx("frame-div2")}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 1 }}
                        transition={{
                          delay: 0.1,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <List
                          gap={0.5}
                          tight={false}
                          style={{ height: "auto" }}
                        >
                          <div className={cx("text-section")}>브랜드</div>
                          <div className={cx("frame-scroll2")}>
                            <List gap={0.25} tight={false}>
                              <Suspense>
                                {brand_like_list.map((brand_data, idx) => (
                                  <CustomLink
                                    key={brand_data.brand_id + idx}
                                    to={"/brand/" + brand_data.brand_id}
                                    style={{ width: "100%" }}
                                  >
                                    <TableCard
                                      key={brand_data.brand_id + idx}
                                      {...brand_data}
                                      id={brand_data.brand_id}
                                      collection="brand"
                                    ></TableCard>
                                  </CustomLink>
                                ))}
                              </Suspense>
                              {brand_like_list.length === 0 && (
                                <motion.div
                                  className={cx("text-error")}
                                  initial={{ opacity: 0.3 }}
                                  animate={{ opacity: 0.7 }}
                                  transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut",
                                    duration: 1.5,
                                  }}
                                >
                                  <div>좋아하는 자재 브랜드가 없어요.</div>
                                  <div>
                                    브랜드 탭에서 좋아하는 브랜드를 추가해봐요.
                                  </div>
                                </motion.div>
                              )}
                            </List>
                          </div>
                        </List>
                      </motion.div>
                      <motion.div
                        className={cx("frame-div2")}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 1 }}
                        transition={{
                          delay: 0.2,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <List
                          gap={0.5}
                          tight={false}
                          style={{ height: "auto" }}
                        >
                          <div className={cx("text-section")}>건축물 사례</div>
                          <div className={cx("frame-scroll2")}>
                            <List gap={0.25} tight={false}>
                              {architecture_like_list.map((e, idx) => (
                                <CustomLink
                                  key={idx}
                                  to={"/architecture/" + e.architecture_id}
                                  style={{ width: "100%" }}
                                >
                                  <TableCard
                                    {...e}
                                    id={e.architecture_id}
                                    collection="architecture"
                                    illust2={
                                      e.illust2 +
                                      (e.address.is_exact
                                        ? ""
                                        : " (위치 부정확)")
                                    }
                                  ></TableCard>
                                </CustomLink>
                              ))}
                              {architecture_like_list.length === 0 && (
                                <motion.div
                                  className={cx("text-error")}
                                  initial={{ opacity: 0.3 }}
                                  animate={{ opacity: 0.7 }}
                                  transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut",
                                    duration: 1.5,
                                  }}
                                >
                                  <div>좋아하는 건축물 사례가 없어요.</div>
                                  <div>
                                    지도 탭에서 좋아하는 사례를 추가해봐요.
                                  </div>
                                </motion.div>
                              )}
                            </List>
                          </div>
                        </List>
                      </motion.div>
                      <div className={cx("frame-margin2")}></div>
                    </List>
                  </ScrollContainer>
                </>
              )}
            </List>
          </div>
        </List>
        <AlertPopupScienario
          popup_data={alert_popup_data}
          setPopupData={setAlertPopupData}
        />
      </div>
    </>
  );
};

export default MaterialBoardPage;
