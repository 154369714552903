export const API_URI = "http://api.materialplatform.kr/";

export const IP_PORT = "http://materialplatform.kr/";

export const SERVICE_URI = "https://materialplatform.kr/";

export const SERVICE_URI_AFTER_SURVEY =
  "https://materialplatform.kr?from=survey";

export const S3_URI =
  "https://materialplatform.s3.ap-northeast-2.amazonaws.com/";

export const SURVEY_PATH = "https://materialplatform.kr/survey";

// export default

// module.exports = { API_URI, IP_PORT, SERVICE_URI };
