import React, { Children, useEffect, useState } from "react";
// { useEffect }

import styles from "./Switch.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as Excel } from "../svgs/Excel.svg";
import { ReactComponent as Autocad } from "../svgs/Autocad.svg";
import useButtonGesture from "../hooks/useButtonGesture.js";
import useGlobalVar from "../hooks/useGlobalVar";
import List from "./List";
import CustomLink from "../component/CustomLink";

const cx = classNames.bind(styles);

const Switch = ({
  type,
  shape,
  to,
  list = [
    { kor: "list[0].kor", eng: "list[0].eng" },
    { kor: "list[1].kor", eng: "list[1].eng" },
  ],
  default_emph_idx = 0,
  color,
  padding,
  onClick,
  tight,
}) => {
  const [global_var, setGlobalVar] = useGlobalVar();

  const [
    Switch_state,
    onMouseDown,
    onMouseUp,
    useEffectContent,
    style_content,
    state,
  ] = useButtonGesture();

  useEffect(useEffectContent, [Switch_state]);

  const [emph_idx, setEmphIdx] = useState(default_emph_idx);
  useEffect(() => setEmphIdx(default_emph_idx), [default_emph_idx]);

  return (
    <div
      className={cx("wrapper", type == "default" ? color : type, state)}
      style={style_content}
    >
      <List type="row" gap={0.125} padding={0.25}>
        {list.map((e, idx) => (
          <CustomLink to={to?.(e)}>
            <div
              key={idx}
              className={cx(
                "frame-button",
                "color-" + (type == "default" ? color : type),
                state,
                idx == emph_idx ? "emph" : ""
              )}
              onClick={() => {
                onClick(e);
                setEmphIdx(idx);
              }}
              onTouchStart={global_var.touchable ? onMouseDown : () => {}}
              onMouseDown={global_var.touchable ? () => {} : onMouseDown}
              onTouchEnd={global_var.touchable ? onMouseUp : () => {}}
              onMouseUp={global_var.touchable ? () => {} : onMouseUp}
              style={style_content}
            >
              {e.kor}
            </div>
          </CustomLink>
        ))}
      </List>
    </div>
  );
};

Switch.defaultProps = {
  type: "default",
  children: "children",
  color: "default",
  onClick: () => {
    console.log("clicked default Switch");
  },
  tight: true,
};

export default Switch;

// ### Switch

// - type: default / excel / cad
// - shape: default / rectangle
// - children: any
// - color: default / transparent / primary
// - padding: int
// - onClick: ()=>any
// - tight: boolean
