import { LOCATION_TYPE, MATERIAL_TYPE, SPEC_TYPE } from "./types";
import { getSortQuery } from "./filterData";
import { S3_URI } from "../shortcut";

export const item_num = 24;

export const getMaterialPageQuery = (
  filter,
  show_type,
  sort_type,
  page_no,
  item_num,
  search_text = ""
) => ({
  collection: "material",
  query: [
    ...((filter?.type?.length ?? 0) > 0
      ? [{ $match: { "filter.type": { $in: filter?.type } } }]
      : []),
    ...(search_text.replace(" ", "").length > 0
      ? [
          {
            $match: {
              $or: search_text.split(" ").map((txt) => ({
                search_text: { $regex: "(?i)" + txt + "(?-i)" },
              })),
            },
          },
        ]
      : []),
    ...getSortQuery(sort_type),
    {
      $project: {
        name: 1,
        material_id: 1,
        summary: 1,
        like_num: 1,
        srcs: 1,
        color_alt: 1,
        original_brand: 1,
        distribution_brand: 1,
        custom_data: 1,
        filter: 1,
      },
    },
    { $skip: item_num * (page_no - 1) },
    { $limit: item_num },
  ],
});

export const getMaterialPageCountQuery = (
  filter,
  show_type,
  sort_type,
  search_text = ""
) => ({
  collection: "material",
  query: [
    ...(filter?.type?.length > 0
      ? [{ $match: { "filter.type": { $in: filter?.type } } }]
      : []),
    ...(search_text.replace(" ", "").length > 0
      ? [
          {
            $match: {
              $or: search_text.split(" ").map((txt) => ({
                search_text: { $regex: "(?i)" + txt + "(?-i)" },
              })),
            },
          },
        ]
      : []),
    ...getSortQuery(sort_type),
    {
      $project: {
        name: 1,
        material_id: 1,
        summary: 1,
        like_num: 1,
        srcs: 1,
        color_alt: 1,
        original_brand: 1,
        distribution_brand: 1,
        custom_data: 1,
      },
    },
    { $count: "totalCount" },
  ],
});

export const getMaterialIllustPageQuery = (id) => ({
  collection: "material",
  query: [{ $match: { material_id: id } }],
});

export const getBrandIllustPageQueryOfMaterialData = (
  id,
  filter,
  show_type,
  sort_type,
  page_no,
  item_num,
  search_text = ""
) => {
  const material_page_query = getMaterialPageQuery(
    filter,
    show_type,
    sort_type,
    page_no,
    item_num,
    search_text
  );
  return {
    collection: material_page_query.collection,
    query: [
      {
        $match: {
          $or: [{ "original_brand.id": id }, { "distribution_brand.id": id }],
        },
      },
      ...material_page_query.query,
    ],
  };
};

export const getBrandIllustPageCountQueryOfMaterialData = (
  id,
  filter,
  show_type,
  sort_type,
  search_text = ""
) => {
  const material_page_query = getMaterialPageCountQuery(
    filter,
    show_type,
    sort_type,
    search_text
  );
  return {
    collection: material_page_query.collection,
    query: [
      {
        $match: {
          $or: [{ "original_brand.id": id }, { "distribution_brand.id": id }],
        },
      },
      ...material_page_query.query,
    ],
  };
};

export const getMapPageQueryOfMaterialData = (
  id_list,
  filter,
  sort_type,
  search_text = ""
) => {
  return {
    collection: "material",
    query: [
      { $match: { material_id: { $in: id_list } } },
      ...((filter?.type?.length ?? 0) > 0
        ? [{ $match: { "filter.type": { $in: filter?.type } } }]
        : []),
      ...(search_text.replace(" ", "").length > 0
        ? [
            {
              $match: {
                $or: search_text.split(" ").map((txt) => ({
                  search_text: { $regex: "(?i)" + txt + "(?-i)" },
                })),
              },
            },
          ]
        : []),
      ...getSortQuery(sort_type),
      {
        $project: {
          name: 1,
          material_id: 1,
          filter: 1,
          srcs: 1,
          original_brand: 1,
          distribution_brand: 1,
          summary: 1,
        },
      },
    ],
  };
};

export const getArchitectureIllustPageQueryOfMaterialData = (
  id_list,
  filter,
  show_type,
  sort_type,
  page_no,
  item_num,
  search_text = ""
) => {
  const material_page_query = getMaterialPageQuery(
    filter,
    show_type,
    sort_type,
    page_no,
    item_num,
    search_text
  );
  return {
    collection: material_page_query.collection,
    query: [
      {
        $match: { material_id: { $in: id_list } },
      },
      ...material_page_query.query,
    ],
  };
};

export const getArchitectureIllustPageCountQueryOfMaterialData = (
  id_list,
  filter,
  show_type,
  sort_type,
  search_text = ""
) => {
  const material_page_query = getMaterialPageCountQuery(
    filter,
    show_type,
    sort_type,
    search_text
  );
  return {
    collection: material_page_query.collection,
    query: [
      {
        $match: { material_id: { $in: id_list } },
      },
      ...material_page_query.query,
    ],
  };
};

export const getMaterialBoardPageQueryOfMaterialData =
  getMapPageQueryOfMaterialData;

export const getRAQPageQueryOfMaterialData =
  getMaterialBoardPageQueryOfMaterialData;

export const convertMaterialPageData = (data) => {
  return data.map((e) => {
    return {
      id: e.material_id,
      title: e.name,
      illust: e.summary.filter((e2) => e2.length > 0).join(" · "),
      src: S3_URI + e.srcs.image[0],
      color_src_list: e.color_alt.map((e2) => S3_URI + e2.src),
      like_num: e.like_num,
      have_2d: e.srcs._2d.length > 0,
      have_3d: e.srcs._3d.length > 0,
      have_texture: e.srcs.texture.length > 0,
      filter: e.filter,
      brand: {
        original_brand: e?.original_brand,
        distribution_brand: e?.distribution_brand,
      },
      custom_data: e?.custom_data,
    };
  });
};

export const convertMaterialPageIllustData = (data) => {
  if (data.length == 0) {
    return {};
  }
  const material_page_data = convertMaterialPageData(data)[0];
  return {
    material_id: data[0].material_id,
    summary: material_page_data,
    brand: material_page_data.brand,
    filter: data[0].filter,
    common_data: {
      title: data[0].name,
      illust: data[0].illust,
      url: data[0].url,
      chips: [
        ...data[0].summary,
        data[0].filter?.type,
        ...data[0].filter?.location,
        ...data[0].filter?.spec,
      ],
      like_num: data[0].like_num,
      srcs: data[0].srcs,
      color_alt: data[0].color_alt,
    },
    custom_data: data[0].custom_data,
  };
};

export const convertMaterialDataForBrandIllustPage = convertMaterialPageData;

export const convertMaterialDataForMapPage = (data) => {
  return data.map((e) => {
    return {
      id: e.material_id,
      illust: e.summary.filter((e2) => e2.length > 0).join(" · "),
      illust2: null,
      title: e.name,
      src: S3_URI + e.srcs.image[0],
      filter: e.filter,
      brand: {
        original_brand: e?.original_brand,
        distribution_brand: e?.distribution_brand,
      },
    };
  });
};

export const convertMaterialDataForArchitectureIllustPage =
  convertMaterialPageData;

export const convertMaterialDataForMaterialBoardPage =
  convertMaterialDataForMapPage;

export const convertMaterialDataForRAQPage = convertMaterialDataForMapPage;

const _material_scheme = {
  material_id: "string",
  name: "string",
  illust: "string",
  summary: ["string"],
  url: "string",
  srcs: {
    image: ["string"],
    _2d: ["string"],
    texture: ["string"],
    _3d: ["string"],
  },
  color_alt: [
    {
      src: "string",
      name: "string",
    },
  ],
  like_num: "number",
  original_brand: { id: "string", name: "string" },
  distribution_brand: { id: "string", name: "string" },
  filter: {
    type: MATERIAL_TYPE,
    location: [LOCATION_TYPE],
    spec: [SPEC_TYPE],
  },
  custom_data: [{ name: "string", value: "string" }],
};

const _converted_data_scheme = {
  material_id: "string",
  summary: {
    id: "string",
    title: "string",
    illust: "string",
    src: "string",
    color_src_list: ["string"],
    like_num: "number",
    have_2d: "boolean",
    have_3d: "boolean",
    have_texture: "boolean",
  },
  brand: {
    original_brand: { id: "string", name: "string" },
    distribution_brand: { id: "string", name: "string" },
  },
  filter: {
    type: MATERIAL_TYPE,
    location: [LOCATION_TYPE],
    spec: [SPEC_TYPE],
  },
  common_data: {
    title: "string",
    illust: "string",
    url: "string",
    chips: ["string"],
    like_num: "number",
    srcs: {
      image: ["string"],
      _2d: ["string"],
      texture: ["string"],
      _3d: ["string"],
    },
    color_alt: [{ src: "string", name: "string" }],
  },
  custom_data: [
    {
      name: "string",
      value: "string",
    },
  ],
};
