import React, { useState, useEffect } from "react";
// { useEffect }

import styles from "./CardList.module.scss";
import classNames from "classnames/bind";
import Tooltip from "../atoms/Tooltip";
import List from "../atoms/List";
import Card from "../atoms/Card";
import Divider from "../atoms/Divider";
import Input from "./Input";
import SummaryCard from "./SummaryCard";
import DataCard from "./DataCard";
import Button from "../atoms/Button";
import { _transformScroll } from "../util/alias";
import useGlobalVar from "../hooks/useGlobalVar";
import { encodeNumberList } from "../util/encodeData";
import { SERVICE_URI } from "../shortcut";
import useGlobalData from "../hooks/useGlobalData";

const cx = classNames.bind(styles);

const CardList = ({ data }) => {
  const [mouse_over, setMouseOver] = useState(false);

  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();

  useEffect(() => {}, []);

  return (
    <List padding={0.75} gap={0} attach="left" tight={false} multiple_line>
      <div className={cx("wrapper")}></div>
    </List>
  );
};

CardList.defaultProps = {
  data: [
    {
      title: "연간 수입가정",
      data_list: [
        [
          {
            type: "default",
            disabled: false,
            operator: "",
            title: "건물 연면적",
            value: "2,000",
            unit: "평",
          },
          {
            type: "rate",
            disabled: false,
            operator: "×",
            title: "전용률",
            value: "65.0",
            unit: "%",
          },
          { type: "divider" },
          {
            type: "default",
            disabled: true,
            operator: "",
            title: "임대면적",
            value: "1,300",
            unit: "평",
          },
        ],
        [
          {
            type: "default",
            disabled: false,
            operator: "×",
            title: "면적당 임대료",
            value: "40,000",
            unit: "원/평·월",
          },
          {
            type: "default",
            disabled: true,
            operator: "×",
            title: "",
            value: "12",
            unit: "개월",
          },
          { type: "divider" },
          {
            type: "default",
            disabled: true,
            operator: "",
            title: "연 NOI",
            value: "624",
            unit: "백만원",
          },
        ],
        [
          {
            type: "rate",
            disabled: false,
            operator: "r",
            title: "연간 물가 상승률",
            value: "2.0",
            unit: "%",
          },
        ],
      ],
    },
  ],
};

export default CardList;

// ### Card

// - shape: default / rectangle
// - children: any
// - padding: int
// - clickable: boolean
// - transparent: boolean
// - onClick: ()=>any
// - use_tooltip: boolean
// - tooltip: [any]
// - tight: boolean
