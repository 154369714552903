import React, { useEffect, useReducer, useState } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./MaterialPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";
import axios from "axios";
import { API_URI } from "../shortcut";
import {
  convertMaterialPageData,
  getMaterialPageCountQuery,
  getMaterialPageQuery,
  item_num,
} from "../functions/handleMaterialData";
import { motion } from "framer-motion";

import PageNoList from "../component/PageNoList";
import Button from "../atoms/Button";
import useQueryParam from "../hooks/useQueryParam";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const MaterialPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [item_list, setItemList] = useState([]);
  const [item_count, setItemCount] = useState(0);
  const [page_no, setPageNo] = useState(1);
  const [data_loaded, setDataLoaded] = useState(false);

  const query = useQueryParam();

  useEffect(() => {
    setDataLoaded(false);
    axios
      .put(
        API_URI + "data",
        getMaterialPageCountQuery(
          global_data.filter?.current,
          global_var.data_show,
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) => {
        setItemCount(res.data?.[0]?.totalCount);
        axios
          .put(
            API_URI + "data",
            getMaterialPageQuery(
              global_data.filter?.current,
              global_var.data_show,
              global_var.data_sort,
              page_no,
              item_num,
              global_data.search_data?.filter?.current ||
                (query.get("search") ?? "")
            )
          )
          .then((res) => {
            setItemList(convertMaterialPageData(res.data));
            setDataLoaded(true);
          })
          .catch(console.log);
      })
      .catch(console.log);
  }, [
    global_data.filter,
    global_var.data_sort,
    global_var.data_show,
    page_no,
    global_data.search_data,
  ]);

  return (
    <>
      <MetaData path="/material" />
      <Header emph="material" />
      <div className={cx("wrapper")}>
        <List gap={0}>
          <div className={cx("body")}>
            <List gap={0} tight={false}>
              <div className={cx("frame-title")}>
                <List gap={0} align="left">
                  <div className={cx("text-title")}>건축자재 살펴보기</div>
                  <div className={cx("text-illust")}>
                    자재,하드웨어 등 원하는 건축자재를 쉽고 빠르게 찾아봐요.
                  </div>
                </List>
              </div>
              <Filter
                onSearch={(e) => {
                  window.history.replaceState({}, "", "/material?search=" + e);
                }}
              />
              <ItemList
                type={global_var.data_show}
                item_list={item_list}
                no_data_alert="지금은 서비스 준비중으로 타일, 벽돌, 창호만 모여 있어요."
                no_searched_data_alert="검색 결과가 없어요. 다시 한번 검색해보세요."
                loading={!data_loaded}
              />

              <div className={cx("frame-space")}></div>
              <PageNoList
                item_count={item_count}
                item_num={item_num}
                onClick={(e) => setPageNo(e)}
              ></PageNoList>
              <div className={cx("frame-space")}></div>
              <div className={cx("frame-space")}></div>
              <Button
                type="brown-light"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                맨 위로 올라가기
              </Button>
              <div className={cx("frame-space")}></div>
              <div className={cx("frame-space")}></div>
            </List>
          </div>
        </List>
      </div>
    </>
  );
};

export default MaterialPage;
