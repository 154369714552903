import React, { Children, useState, useLayoutEffect, useEffect } from "react";
// { useEffect }

import styles from "./Like.module.scss";
import classNames from "classnames/bind";

import { ReactComponent as IconLike } from "../svgs/IconLike.svg";
import List from "../atoms/List";
import Like from "./Like";
import useGlobalData from "../hooks/useGlobalData";
import { _deepcopy } from "../util/alias";
import axios from "axios";
import { API_URI } from "../shortcut";
import { ReactComponent as IconLikeSmall } from "../svgs/IconLikeSmall.svg";

const cx = classNames.bind(styles);

const LikeAutomatedById = ({
  id = "",
  collection = "",
  like_num,
  onClick = () => {
    console.log("clicked like component");
  },
  show_num,
  sensitive = false,
  small,
}) => {
  // 아래에 개별 아이콘 호출어 추가

  const [like, setLike] = useState(null);
  const [new_like_num, setNewLikeNum] = useState(0);
  const [global_data, setGlobalData] = useGlobalData();

  useLayoutEffect(() => {
    setNewLikeNum(like_num);
  }, [like_num]);

  useLayoutEffect(() => {
    const like_list = global_data.like_data?.[collection];
    if (like_list) {
      setLike(like_list.includes(id));
    }
  }, [id, collection]);

  useEffect(() => {
    if (sensitive) {
      const like_list = global_data.like_data?.[collection];
      if (like_list && like !== null) {
        if (like && !like_list.includes(id)) {
          setNewLikeNum((n) => n - 1);
        } else if (!like && like_list.includes(id)) {
          setNewLikeNum((n) => n + 1);
        }
        setLike(like_list.includes(id));
      }
    }
  }, [global_data.like_data]);

  const handleLikeNum = async (id, collection, like) => {
    return axios.put(API_URI + "handleLikeNum", {
      id,
      collection,
      like,
      id_key: "_id",
    });
  };

  return small ? (
    like ? (
      <IconLikeSmall />
    ) : null
  ) : (
    <Like
      onClick={(e, new_like) => {
        onClick(e);
        console.log(e, new_like);
        handleLikeNum(id, collection, new_like)
          .then(() => {
            setGlobalData((data) => {
              const like_data = _deepcopy(data.like_data) ?? {};
              const like_list = like_data[collection] ?? [];
              if (new_like) {
                like_data[collection] = [...like_list, id];
              } else {
                like_data[collection] = like_list.filter((e) => e !== id);
              }
              return { like_data };
            });
          })
          .catch(console.log);
      }}
      like={like}
      like_num={new_like_num}
      show_num={show_num}
    ></Like>
  );
};

export default LikeAutomatedById;
