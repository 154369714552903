import React, { useEffect, useState, useReducer, useRef } from "react";
// , { useEffect }

import styles from "./Search.module.scss";
import classNames from "classnames/bind";

import { cloneDeep } from "lodash";
import Icon from "./Icon";
import Divider from "./Divider";
import useGlobalVar from "../hooks/useGlobalVar";
import List from "./List";
import { ReactComponent as IconSearch } from "../svgs/IconSearch.svg";
import { motion } from "framer-motion";
import { _deepcopy } from "../util/alias";
import useGlobalData from "../hooks/useGlobalData";

const cx = classNames.bind(styles);

const Search = ({
  id = "general",
  color,
  placeholder = "검색하기",
  onClick = (e) => {
    console.log(e);
  },
  search_list,
  dropdown_width,
  onChange = () => {},
}) => {
  const [clicked, setClicked] = useState(false);
  const [global_data, setGlobalData] = useGlobalData();
  const list_state = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 1 },
  };
  const input_tag = useRef();
  const btn_tag = useRef();

  const onClickAfterDataUpdate = (e) => {
    setGlobalData((data) => {
      const search_data = _deepcopy(data.search_data) ?? {};
      search_data[id] = search_data[id] ?? {};
      search_data[id].current = e;
      if (typeof e === typeof {} || e.replaceAll(" ", "").length > 0) {
        search_data[id].history = search_data[id].history ?? [];
        search_data[id].history.unshift(e);
        search_data[id].history.splice(10);
      }
      return { search_data };
    });
    onClick(e);
  };

  useEffect(() => {
    setGlobalData((data) => {
      const search_data = _deepcopy(data.search_data) ?? {};
      search_data[id] = search_data[id] ?? {};
      search_data[id].current = "";
      return { search_data };
    });
  }, []);
  const result_ref = useRef();

  // useEffect(() => {
  //   return () => {
  //     onClick("");
  //   };
  // }, []);
  return (
    <div
      className={cx("wrapper")}
      onMouseEnter={() => setClicked(true)}
      onMouseLeave={() => setClicked(false)}
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          onClickAfterDataUpdate(search_list?.[0] ?? input_tag.current.value);
          setClicked(false);
          input_tag.current.blur();
        }
      }}
      onChange={(e) => {
        // console.log(input_tag.current.value);
        setClicked(true);
        onChange(input_tag.current.value);
        if (result_ref.current) {
          result_ref.current.scrollTop = 0;
        }
      }}
    >
      <div className={cx("frame-btn", color)}>
        <List type="row">
          <input
            className={cx("frame-text")}
            placeholder={
              global_data.search_data?.[id]?.current?.length == 0
                ? placeholder
                : typeof global_data.search_data?.[id]?.current === typeof {}
                ? global_data.search_data?.[id]?.current?.title
                : global_data.search_data?.[id]?.current
            }
            ref={input_tag}
          />
          <div
            className={cx("frame-icon")}
            onClick={() =>
              onClickAfterDataUpdate(
                search_list?.[0] ?? input_tag.current.value
              )
            }
            ref={btn_tag}
          >
            <IconSearch />
          </div>
        </List>
      </div>
      <motion.div
        className={cx("frame-dropdown", clicked ? "" : "hidden")}
        initial={clicked ? "hidden" : "visible"}
        animate={clicked ? "visible" : "hidden"}
        variants={list_state}
        transition={{ duration: 0 }}
      >
        <List align="right" tight={false} fillY>
          {search_list && (
            <>
              <div className={cx("txt-subsection")}>검색결과</div>
              <div className={cx("frame-dropdown-inside")} ref={result_ref}>
                <List padding={0.25} gap={0.125} align="right" tight={false}>
                  {search_list.map((e, idx) => (
                    <motion.div
                      key={idx}
                      className={cx("txt-dropdown")}
                      onClick={() => {
                        input_tag.current.value = e.title;
                        onClickAfterDataUpdate(e);
                        setClicked(false);
                      }}
                      // transition={{ delay: 0.05 * idx }}
                      // variants={item_state}
                    >
                      <div className={cx("txt-dropdown-title")}>{e.title}</div>
                      {e.illust && (
                        <div className={cx("txt-dropdown-illust")}>
                          {e.illust}
                        </div>
                      )}
                      {e.illust2 && (
                        <div className={cx("txt-dropdown-illust")}>
                          {e.illust2}
                        </div>
                      )}
                    </motion.div>
                  ))}
                </List>
              </div>
            </>
          )}

          <div className={cx("txt-subsection")}>최근 검색항목</div>
          <div className={cx("frame-dropdown-inside")}>
            <List padding={0.25} gap={0.125} align="right" tight={false}>
              {(global_data.search_data?.[id]?.history ?? []).map((e, idx) => (
                <motion.div
                  key={idx}
                  className={cx("txt-dropdown")}
                  onClick={() => {
                    input_tag.current.value =
                      typeof e === typeof {} ? e.title : e;
                    onClickAfterDataUpdate(e);
                    setClicked(false);
                  }}
                  // transition={{ delay: 0.05 * idx }}
                  // variants={item_state}
                >
                  {typeof e === typeof {} ? e.title : e}
                </motion.div>
              ))}
            </List>
          </div>
        </List>
      </motion.div>
    </div>
  );
};

export default Search;
