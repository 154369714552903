import React, { useState } from "react";
// { useEffect }

import styles from "./DropDown.module.scss";
import classNames from "classnames/bind";
import List from "./List";
import { ReactComponent as IconDropDown } from "../svgs/IconDropDown.svg";
import { motion } from "framer-motion";
import useGlobalVar from "../hooks/useGlobalVar";
import { ipad_width } from "../util/style";

const cx = classNames.bind(styles);

const DropDown = ({
  name = "name",
  list = [
    {
      kor: "list[0].kor",
      eng: "list[0].eng",
    },
    {
      kor: "list[1].kor",
      eng: "list[1].eng",
    },
  ],
  emph_idx,
  onClick = (e) => {
    console.log(e);
  },
}) => {
  const [clicked, setClicked] = useState(false);
  const list_state = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 1 },
  };
  const [global_var, setGlobarVar] = useGlobalVar();

  const item_state = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 5 },
  };

  return (
    <div
      className={cx("wrapper")}
      onMouseEnter={
        global_var.media_mobile ? undefined : () => setClicked(true)
      }
      onMouseLeave={() => setClicked(false)}
      // onBlur={() => setClicked(false)}
      // onTouchLeave={() => setClicked(false)}
      onClick={() => setClicked((p) => !p)}
    >
      <div className={cx("frame-btn")}>
        <List type="row">
          <div className={cx("frame-text")}>
            {emph_idx > -1 ? list[emph_idx].kor : name}
          </div>
          <div className={cx("frame-icon")}>
            <IconDropDown />
          </div>
        </List>
      </div>
      <motion.div
        className={cx("frame-dropdown", clicked ? "" : "hidden")}
        initial={clicked ? "hidden" : "visible"}
        animate={clicked ? "visible" : "hidden"}
        variants={list_state}
        transition={{ duration: 0 }}
      >
        <List padding={0.25} gap={0.125} align="right" tight={false}>
          {list.map((e, idx) => (
            <motion.div
              key={idx}
              className={cx("txt-dropdown", emph_idx == idx ? "emph" : "")}
              onClick={() => {
                onClick(emph_idx == idx ? -1 : idx);
                // if (global_var.window_width < ipad_width) {
                //   setClicked(false);
                // }
              }}
              // transition={{ delay: 0.05 * idx }}
              // variants={item_state}
            >
              {e.kor}
            </motion.div>
          ))}
        </List>
      </motion.div>
    </div>
  );
};

export default DropDown;

// ### DropDown

// - style: default / bold / dashed / faint
// - color: primary / grey
