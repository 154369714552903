import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useLayoutEffect,
  forwardRef,
  useRef,
  useState,
} from "react";
// { useEffect }
import useKakaoEvent from "../kakaoHooks/useKakaoEvent";
// import styles from "./KakaoCustomOverlay.module.scss";
import classNames from "classnames/bind";
import useKakaoMap from "../kakaoHooks/useKakaoMap";

// const cx = classNames.bind(styles);

const KakaoCustomOverlay = forwardRef(
  (
    {
      clickable = true,
      children,
      lat = 35,
      lng = 126,
      xAnchor = 0.5,
      yAnchor = 1,
      zIndex = 3,
      onClick = () => {},
      onCreate = () => {},
      onMouseDown = () => {},
      onMouseMove = () => {},
      onMouseOut = () => {},
      onMouseOver = () => {},
      visible = true,
    },
    ref
  ) => {
    const kakao_map = useKakaoMap("KakaoCustomOverlay");
    const children_ref = useRef();
    const [content, setContent] = useState(undefined);
    const custom_overlay = useMemo(() => {
      console.log(content);
      return new window.kakao.maps.CustomOverlay({
        map: kakao_map,
        clickable,
        content,
        position: new window.kakao.maps.LatLng(lat, lng),
        xAnchor,
        yAnchor,
        zIndex,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xAnchor, yAnchor, clickable]);

    useImperativeHandle(ref, () => custom_overlay, [custom_overlay]);

    useEffect(() => {
      setContent(children_ref.current);
    }, [children_ref.current]);

    useLayoutEffect(() => {
      custom_overlay.setMap(kakao_map);
      return () => custom_overlay.setMap(null);
    }, [kakao_map, custom_overlay]);

    useLayoutEffect(() => {
      const onDelete = onCreate(custom_overlay);
      return () => onDelete?.();
    }, [custom_overlay, onCreate]);

    useLayoutEffect(() => {
      custom_overlay.setPosition(new window.kakao.maps.LatLng(lat, lng));
    }, [custom_overlay, lat, lng]);

    useLayoutEffect(() => {
      custom_overlay.setContent(content);
    }, [custom_overlay, content]);
    useLayoutEffect(() => {
      custom_overlay.setVisible(visible);
    }, [custom_overlay, visible]);
    useLayoutEffect(() => {
      custom_overlay.setZIndex(zIndex);
    }, [custom_overlay, zIndex]);

    useKakaoEvent(custom_overlay, "mouseover", onMouseOver);
    useKakaoEvent(custom_overlay, "mouseout", onMouseOut);
    useKakaoEvent(custom_overlay, "mousemove", onMouseMove);
    useKakaoEvent(custom_overlay, "mousedown", onMouseDown);
    useKakaoEvent(custom_overlay, "click", onClick);

    return (
      <div>
        <div ref={children_ref}>{children}</div>
      </div>
    );
  }
);

export default KakaoCustomOverlay;

// ### ValuationCompText

// - style: default / detail / total
// - use_tooltip: True / False
// - use_toggle: True / False
// - tooltip
// - title, value, unit, second_value, second_unit
// - toggle_content <=children
