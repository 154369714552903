import React, {
  useLayoutEffect,
  useReducer,
  useState,
  Suspense,
  useEffect,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./MaterialIllustPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _formatSuperAndSubscript, _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";
import Button from "../atoms/Button";
import Like from "../component/Like";
import Chip from "../atoms/Chip";
import Switch from "../atoms/Switch";
import LoadedImage from "../atoms/LoadedImage";
import { motion } from "framer-motion";
import TableCard from "../component/TableCard";
import {
  convertMaterialPageIllustData,
  getMaterialIllustPageQuery,
} from "../functions/handleMaterialData";
import axios from "axios";
import { API_URI, S3_URI } from "../shortcut";
import LikeAutomatedById from "../component/LikeAutomatedById";
import {
  convertBrandDataForMaterialIllustPage,
  getMaterialIllustPageQueryOfBrandData,
} from "../functions/handleBrandData";
import CustomLink from "../component/CustomLink";
import AlertPopupScienario from "../component/AlertPopupScienario";
import {
  convertArchitectureDataForMaterialIllustPage,
  getMaterialIllustPageQueryOfArchitectureData,
} from "../functions/handleArchitectureData";
import { ipad_width } from "../util/style";
import MetaData from "./MetaData";
import ScrollContainer from "react-indiana-drag-scroll";
import { ReactComponent as IconDropDown } from "../svgs/IconDropDown.svg";

const cx = classNames.bind(styles);

const MaterialIllustPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const show_type_list = [
    { kor: "제품 이미지", eng: "image" },
    { kor: "도면", eng: "2D" },
    { kor: "텍스쳐", eng: "Tx" },
    { kor: "3D 모델", eng: "3D" },
  ];
  const [show_type, setShowType] = useState("image");
  const [material_data, setMaterialData] = useState({});
  const [brand_id, setBrandId] = useState();
  const [brand_data, setBrandData] = useState({});
  const [alert_popup_data, setAlertPopupData] = useState({});
  const [architecture_data_list, setArchitectureDataList] = useState([]);
  const [close, setClose] = useState(true);

  useLayoutEffect(() => {
    axios
      .put(
        API_URI + "data",
        getMaterialIllustPageQuery(decodeURIComponent(match?.params?.id))
      )
      .then((res) => {
        const page_illust_data = convertMaterialPageIllustData(res.data);
        setMaterialData(page_illust_data);
        setBrandId(
          page_illust_data.brand?.distribution_brand?.id ||
            page_illust_data.brand?.original_brand?.id
        );
      })
      .catch(console.log);
  }, [match]);

  useLayoutEffect(() => {
    if (brand_id) {
      axios
        .put(API_URI + "data", getMaterialIllustPageQueryOfBrandData(brand_id))
        .then((res) =>
          setBrandData(convertBrandDataForMaterialIllustPage(res.data))
        )
        .catch(console.log);
    }
  }, [brand_id]);

  useEffect(() => {
    if (material_data.material_id) {
      axios
        .put(
          API_URI + "data",
          getMaterialIllustPageQueryOfArchitectureData(
            material_data.material_id,
            // "큐블럭 S시리즈 타일 탱고레드",
            global_var.data_sort
          )
        )
        .then((res) =>
          setArchitectureDataList(
            convertArchitectureDataForMaterialIllustPage(res.data)
          )
        )
        .catch(console.log);
    }
  }, [material_data, global_var.data_sort]);

  return (
    <>
      <MetaData path="/material" />
      <Header emph="material" />
      <div className={cx("wrapper")}>
        {/* <LoadedImage
          className={cx("background")}
          src="https://t4.ftcdn.net/jpg/02/20/66/39/240_F_220663994_qXlGkI4TV9WkPbraM0tjcTNrVcyLWF9D.jpg"
          duration={0.1}
        ></LoadedImage> */}

        <List gap={0} tight={false}>
          <div className={cx("body")}>
            {material_data.material_id ? (
              <List gap={1} tight={false}>
                <div className={cx("frame-title")}>
                  <List
                    type={
                      global_var.window_width < ipad_width ? "column" : "row"
                    }
                    align={
                      global_var.window_width < ipad_width ? "left" : "center"
                    }
                    attach="space"
                    gap={1}
                  >
                    <List gap={0.25} align="left">
                      <List gap={0} align="left">
                        <div className={cx("text-title")}>
                          {material_data.common_data?.title}
                        </div>
                        <div className={cx("text-illust")}>
                          {material_data.common_data?.illust}
                        </div>
                      </List>
                      {material_data.common_data?.chips?.length > 0 && (
                        <List
                          type="row"
                          align="center"
                          gap={0.375}
                          tight={false}
                          multiple_line
                        >
                          {material_data.common_data?.chips?.map((e, idx) => (
                            <Chip clicked key={idx}>
                              {e}
                            </Chip>
                          ))}
                        </List>
                      )}
                    </List>
                    <List
                      type={
                        global_var.window_width < ipad_width
                          ? "row-reverse"
                          : "row"
                      }
                      align="center"
                      gap={1}
                    >
                      <LikeAutomatedById
                        like_num={material_data.common_data?.like_num}
                        collection="material"
                        id={material_data.material_id}
                        show_num
                      />
                      <Button
                        color="green-light"
                        onClick={() => {
                          window.open(
                            material_data.common_data?.url,
                            material_data.material_id
                          );
                        }}
                        GTM="MaterialIllustPage_사이트_방문하기"
                      >
                        사이트 방문하기
                      </Button>
                    </List>
                  </List>
                </div>

                <div className={cx("frame-content")}>
                  <List
                    type={
                      global_var.window_width < ipad_width ? "column" : "row"
                    }
                    gap={1}
                    tight={false}
                    fillY
                  >
                    <div className={cx("frame-image")}>
                      <List align="center" attach="center" tight={false}>
                        {show_type == "image" ? (
                          <Suspense
                            fallback={
                              <motion.div
                                className={cx("frame-loading")}
                                initial={{ opacity: 0.3 }}
                                animate={{ opacity: 0.7 }}
                                transition={{
                                  repeat: Infinity,
                                  repeatType: "reverse",
                                  ease: "easeInOut",
                                  duration: 0.8,
                                }}
                              ></motion.div>
                            }
                          >
                            <LoadedImage
                              className={cx("image")}
                              src={
                                S3_URI +
                                material_data.common_data?.srcs?.image?.[0]
                              }
                              duration={0.1}
                              onClick={() => {
                                window.open(
                                  material_data.common_data?.url,
                                  material_data.material_id
                                );
                              }}
                            ></LoadedImage>
                          </Suspense>
                        ) : (
                          <motion.div
                            className={cx("text-alert")}
                            initial={{ opacity: 0.3 }}
                            animate={{ opacity: 0.7 }}
                            transition={{
                              repeat: Infinity,
                              repeatType: "reverse",
                              ease: "easeInOut",
                              duration: 1.5,
                            }}
                          >
                            서비스 준비중이예요.
                          </motion.div>
                        )}
                      </List>

                      <div className={cx("frame-image-overlay")}>
                        <List
                          type="row"
                          padding={0.5}
                          gap={0.5}
                          // attach="end"
                          align="left"
                          tight={false}
                          multiple_line
                        >
                          <Switch
                            color="green-light"
                            list={show_type_list}
                            onClick={(e) => setShowType(e.eng)}
                          ></Switch>
                          {show_type == "image" ? (
                            material_data.common_data?.color_alt?.length > 0 ? (
                              <motion.div
                                className={cx("frame-image-dropdown")}
                                initial={{
                                  height:
                                    global_var.window_width < ipad_width
                                      ? "1.5rem"
                                      : "1.25rem",
                                  boxShadow:
                                    "0 19px 38px rgba(0, 0, 0, 0.0), 0 15px 12px rgba(0, 0, 0, 0.0)",
                                  borderRadius: ".5rem",
                                }}
                                animate={
                                  close
                                    ? {
                                        height:
                                          global_var.window_width < ipad_width
                                            ? "1.5rem"
                                            : "1.25rem",
                                        boxShadow:
                                          "0 19px 38px rgba(0, 0, 0, 0.0), 0 15px 12px rgba(0, 0, 0, 0.0)",
                                        borderRadius: ".5rem",
                                      }
                                    : {
                                        height: "200px",
                                        boxShadow:
                                          "0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "1rem",
                                      }
                                }
                                onClick={() => setClose((e) => !e)}
                              >
                                <List tight={false} align="left" gap={0.5}>
                                  <List type="row" tight={false}>
                                    <motion.div
                                      className={cx("text-button-section")}
                                      initial={{
                                        paddingLeft: ".75rem",
                                        paddingTop: "0.25rem",
                                      }}
                                      animate={
                                        close
                                          ? {
                                              paddingLeft: ".75rem",
                                              paddingTop: "0.25rem",
                                            }
                                          : {
                                              paddingLeft: "0.25rem",
                                              paddingTop: "0.5rem",
                                            }
                                      }
                                    >
                                      세부 종류{close ? " 보기" : ""}
                                    </motion.div>
                                    <div
                                      className={cx(
                                        "frame-icon",
                                        close ? "close" : ""
                                      )}
                                    >
                                      <IconDropDown />
                                    </div>
                                  </List>
                                  <div
                                    className={cx(
                                      "frame-image-dropdown-scroll"
                                    )}
                                  >
                                    <Suspense>
                                      <List
                                        gap={0.25}
                                        tight={false}
                                        align="left"
                                      >
                                        {(
                                          material_data.common_data
                                            ?.color_alt ?? []
                                        ).map((color_data, idx) => (
                                          <List
                                            type="row"
                                            gap={0.25}
                                            // align="left"
                                          >
                                            <div
                                              className={cx(
                                                "frame-overlay-color"
                                              )}
                                              key={idx}
                                            >
                                              <LoadedImage
                                                className={cx("image-color")}
                                                src={S3_URI + color_data.src}
                                                width={"52px"}
                                                height={"52px"}
                                                onError={(e) =>
                                                  (e.currentTarget.src =
                                                    "/img/icon_material.png")
                                                }
                                                duration={0.3}
                                                delay={0.3}
                                              ></LoadedImage>
                                            </div>
                                            <div className={cx("image-illust")}>
                                              {color_data.name == ""
                                                ? "-"
                                                : color_data.name}
                                            </div>
                                          </List>
                                        ))}
                                      </List>
                                    </Suspense>
                                  </div>
                                </List>
                              </motion.div>
                            ) : null
                          ) : (
                            <Button
                              color="green-light"
                              onClick={() =>
                                setAlertPopupData({
                                  data: "다운로드",
                                })
                              }
                              GTM="MaterialIllustPage_다운로드"
                            >
                              다운로드
                            </Button>
                          )}
                        </List>
                      </div>
                    </div>
                    <div className={cx("frame-info")}>
                      <List gap={1} tight={false}>
                        <div className={cx("frame-div")}>
                          <List padding={0.5} gap={0.5} tight={false}>
                            <div className={cx("text-section")}>제품 정보</div>
                            <div className={cx("frame-scroll")}>
                              <CustomLink to={"/brand/" + brand_data.id}>
                                <TableCard {...brand_data} />
                              </CustomLink>
                              <List padding={0.25} gap={0.375} tight={false}>
                                <List
                                  type="row"
                                  attach="space"
                                  align="left"
                                  tight={false}
                                >
                                  <div className={cx("text-name")}>
                                    {material_data.brand?.distribution_brand?.id
                                      ? "유통 브랜드"
                                      : "원브랜드"}
                                  </div>
                                  <div className={cx("text-value")}>
                                    {material_data.brand?.distribution_brand?.id
                                      ? material_data.brand?.distribution_brand
                                          ?.name
                                      : material_data.brand?.original_brand
                                          ?.name}
                                  </div>
                                </List>

                                {[
                                  ...material_data.custom_data,
                                  { name: "납품소요기간", value: "-" },
                                  {
                                    name: "전화번호",
                                    value: brand_data.contact?.phone || "-",
                                  },
                                  {
                                    name: "메일주소",
                                    value: brand_data.contact?.email || "-",
                                  },
                                ].map((e, idx) => (
                                  <List
                                    key={idx}
                                    type="row"
                                    attach="space"
                                    align="left"
                                    tight={false}
                                  >
                                    <div className={cx("text-name")}>
                                      {e.name}
                                    </div>
                                    <div className={cx("text-value")}>
                                      {_formatSuperAndSubscript(e.value)}
                                    </div>
                                  </List>
                                ))}
                                <div className={cx("frame-space")}></div>
                              </List>
                            </div>
                            <Button
                              color="brown-light"
                              fillX
                              style={{ flexShrink: 0 }}
                              onClick={() =>
                                setAlertPopupData({
                                  data: "견적메일 요청",
                                })
                              }
                              GTM={"MaterialIllustPage_견적요청"}
                            >
                              견적메일 요청하기
                            </Button>
                          </List>
                        </div>
                        <div className={cx("frame-div")}>
                          <List padding={0.5} gap={0.5} tight={false}>
                            <div className={cx("text-section")}>
                              적용된 건축 사례
                            </div>
                            <div className={cx("frame-scroll")}>
                              <List gap={0.25} tight={false}>
                                {architecture_data_list.map((e, idx) => (
                                  <CustomLink
                                    key={idx}
                                    to={"/architecture/" + e.architecture_id}
                                    style={{ width: "100%" }}
                                  >
                                    <TableCard
                                      {...e}
                                      id={e.architecture_id}
                                      collection="architecture"
                                      illust2={
                                        e.illust2 +
                                        (e.address.is_exact
                                          ? ""
                                          : " (위치 부정확)")
                                      }
                                    ></TableCard>
                                  </CustomLink>
                                ))}
                                {architecture_data_list.length === 0 && (
                                  <motion.div
                                    className={cx("text-error")}
                                    initial={{ opacity: 0.3 }}
                                    animate={{ opacity: 0.7 }}
                                    transition={{
                                      repeat: Infinity,
                                      repeatType: "reverse",
                                      ease: "easeInOut",
                                      duration: 1.5,
                                    }}
                                  >
                                    <div>해당 재질이 적용된 건축사례가</div>
                                    <div>아직 등록되어 있지 않아요.</div>
                                  </motion.div>
                                )}
                              </List>
                            </div>
                          </List>
                        </div>
                      </List>
                    </div>
                  </List>
                </div>
              </List>
            ) : (
              <></>
            )}
          </div>
        </List>
        <AlertPopupScienario
          popup_data={alert_popup_data}
          setPopupData={setAlertPopupData}
        />
      </div>
    </>
  );
};

export default MaterialIllustPage;
