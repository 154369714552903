import React, { useState } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./MapPicker.module.scss";
import List from "../atoms/List";

import { _transformScroll } from "../util/alias";
import LoadedImage from "../atoms/LoadedImage";
import { motion } from "framer-motion";
import TableCard from "./TableCard";

import { Link } from "react-router-dom";
import KakaoCustomOverlay from "../kakaoComponent/KakaoCustomOverlay";
import ScrollContainer from "react-indiana-drag-scroll";
import { ReactComponent as IconMapPicker } from "../svgs/IconMapPicker.svg";
import LikeAutomatedById from "./LikeAutomatedById";
import Chip from "../atoms/Chip";
import useGlobalVar from "../hooks/useGlobalVar";

const cx = classNames.bind(styles);
// var mapDiv = document.getElementById('map');
// var map = new naver.maps.Map(mapDiv);

const MapPicker = ({
  lat = 37.513,
  lng = 127.025,
  rand = [0, 0],
  id = "id",
  collection = "architecture",
  src,
  title = "title",
  illust = "illust",
  illust2 = "illust2",
  material_list = [
    { title: "m_l[0].t", clicked: true },
    { title: "m_l[1].t" },
    { title: "m_l[2].t" },
  ],
  like_num = 0,
  force_hover = false,
  exact = true,
}) => {
  const [mouse_over, setMouseOver] = useState(false);
  const [global_var, setGlobalVar] = useGlobalVar();

  return (
    <KakaoCustomOverlay
      lat={lat - rand[0] / 2000}
      lng={lng - rand[1] / 2000}
      xAnchor={0.4 + rand[0] / 3}
      yAnchor={1.1275 - rand[1] / 3}
      zIndex={mouse_over ? 2 : 1}
    >
      <div
        onMouseEnter={
          global_var.media_mobile
            ? undefined
            : () => {
                setMouseOver(true);
              }
        }
        onMouseLeave={
          global_var.media_mobile
            ? undefined
            : () => {
                setMouseOver(false);
              }
        }
      >
        <Link to={"/architecture/" + id}>
          <div className={cx("frame-popup")}>
            <div className={cx("frame-image", exact ? "" : "not-exact")}>
              <LoadedImage width={32} height={32} delay={0} src={src} />
            </div>
            {exact ? (
              <IconMapPicker className={cx("frame-picker")} />
            ) : (
              <div className={cx("frame-image-not-exact")} />
            )}
          </div>
          <div className={cx("frame-like-small")}>
            <LikeAutomatedById
              like_num={like_num}
              collection={collection}
              id={id}
              show_num
              sensitive
              small
            />
          </div>
        </Link>
        {mouse_over || force_hover ? (
          <div className={cx("frame-image-overlay-container")}>
            <div className={cx("frame-image-overlay")}>
              <List type="row" padding={0.25} attach="space">
                <List
                  type="column"
                  align="left"
                  style={{ paddingLeft: "0.5rem", minWidth: "0px" }}
                  tight={false}
                >
                  <div className={cx("text-title")}>{title}</div>
                  <div className={cx("text-illust")}>{illust}</div>
                  <div className={cx("text-illust")}>{illust2}</div>
                </List>
                <LikeAutomatedById
                  like_num={like_num}
                  collection={collection}
                  id={id}
                  show_num
                  sensitive
                />
              </List>
              <ScrollContainer
                horizontal
                vertical={false}
                className={cx("frame-image-overlay-scroll")}
                hideScrollbars={false}
              >
                <List type="row" gap={0.3125}>
                  {material_list.map((material_data, idx) => (
                    <Chip
                      key={idx}
                      clickable={false}
                      clicked={material_data.clicked}
                      fixed
                    >
                      {material_data.title}
                    </Chip>
                  ))}
                </List>
              </ScrollContainer>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </KakaoCustomOverlay>
  );
};

export default MapPicker;
