import { getSortQuery } from "./filterData";
import { S3_URI } from "../shortcut";

export const getBrandPageQueryOfBrandData = (id_list, search_text = "") => ({
  collection: "brand",
  query: [
    { $match: { brand_id: { $in: id_list } } },
    ...(search_text.replace(" ", "").length > 0
      ? [
          {
            $match: {
              $or: search_text.split(" ").map((txt) => ({
                search_text: { $regex: "(?i)" + txt + "(?-i)" },
              })),
            },
          },
        ]
      : []),
  ],
});

export const getBrandIllustPageQueryOfBrandData = (id) => ({
  collection: "brand",
  query: [{ $match: { brand_id: id } }],
});

export const getMaterialIllustPageQueryOfBrandData =
  getBrandIllustPageQueryOfBrandData;

export const getMaterialBoardPageQueryOfBrandData = (
  id_list,
  sort_type,
  search_text = ""
) => {
  return {
    collection: "brand",
    query: [
      { $match: { brand_id: { $in: id_list } } },
      ...(search_text.replace(" ", "").length > 0
        ? [
            {
              $match: {
                $or: search_text.split(" ").map((txt) => ({
                  search_text: { $regex: "(?i)" + txt + "(?-i)" },
                })),
              },
            },
          ]
        : []),
      ...getSortQuery(sort_type),
    ],
  };
};

export const convertBrandDataForBrandPage = (data) => {
  const output_data = {};
  data.map((e) => {
    const each_data = convertBrandDataForBrandIllustPage([e]);
    output_data[each_data.brand_id] = each_data;
  });
  console.log(output_data);
  return output_data;
};

export const convertBrandDataForBrandIllustPage = (data) => {
  if (data.length == 0) {
    return {};
  }
  data = data[0];
  return {
    brand_id: data.brand_id,
    title: data.name,
    name_alt: data.name_alt,
    site_title: data.site.title,
    illust: data.illust || data.site.description,
    src:
      data.image.length > 0
        ? S3_URI + data.image
        : data.site.thumbnail.length > 0
        ? S3_URI + data.site.thumbnail
        : data.site.logo.length > 0
        ? S3_URI + data.site.logo
        : "",
    url: data.url,
    contact: data.contact,
    like_num: data.like_num,
  };
};

export const convertBrandDataForMaterialIllustPage = (data) => {
  if (data.length == 0) {
    return {};
  }
  data = data[0];
  return {
    id: data.brand_id,
    collection: "brand",
    brand_id: data.brand_id,
    title: data.name,
    illust: data.illust || data.site.description,
    src:
      data.image.length > 0
        ? S3_URI + data.image
        : data.site.thumbnail.length > 0
        ? S3_URI + data.site.thumbnail
        : data.site.logo.length > 0
        ? S3_URI + data.site.logo
        : "",
    illust2: data.url,
    like_num: data.like_num,
    contact: data.contact,
  };
};

export const convertBrandDataForMaterialBoardPage = (data) => {
  return data.map((e) => convertBrandDataForMaterialIllustPage([e]));
};

const _brand_scheme = {
  brand_id: "string",
  name: "string",
  name_alt: ["string"],
  illust: "string",
  image: "string",
  url: "string",
  like_num: "number",
  contact: {
    phone: "string",
    email: "string",
    url: ["string"],
  },
  site: {
    title: "string",
    description: "string",
    logo: "string",
    thumbnail: "string",
  },
};

const _converted_data_scheme = {
  brand_id: "string",
  title: "string",
  name_alt: ["string"],
  site_title: "string",
  illust: "string",
  src: "string",
  url: "string",
  like_num: "number",
  contact: {
    phone: "string",
    email: "string",
    url: ["string"],
  },
};
