import React, { useEffect, useRef, forwardRef } from "react";

const AutoFocusTextarea = forwardRef((param, ref) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return <textarea ref={ref} {...param} />;
});

export default AutoFocusTextarea;
