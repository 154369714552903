import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useState,
  Suspense,
  useRef,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./MapPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";
import Button from "../atoms/Button";
import Like from "../component/Like";
import Chip from "../atoms/Chip";
import Switch from "../atoms/Switch";
import LoadedImage from "../atoms/LoadedImage";
import { motion } from "framer-motion";
import TableCard from "../component/TableCard";
import Search from "../atoms/Search";
import { ReactComponent as IconDropDown } from "../svgs/IconDropDown.svg";

import { Link } from "react-router-dom";
import { KakaoMapProvider } from "../kakaoHooks/useKakaoMap";
import KakaoCustomOverlay from "../kakaoComponent/KakaoCustomOverlay";
import ScrollContainer from "react-indiana-drag-scroll";
import { ReactComponent as IconMapPicker } from "../svgs/IconMapPicker.svg";
import useQueryParam from "../hooks/useQueryParam";
import MapPicker from "../component/MapPicker";
import axios from "axios";
import { API_URI } from "../shortcut";
import {
  convertMaterialDataForMapPage,
  getMapPageQueryOfMaterialData,
} from "../functions/handleMaterialData";
import CustomLink from "../component/CustomLink";
import {
  convertArchitectureDataForMapPage,
  getMapPageQueryOfArchitectureData,
} from "../functions/handleArchitectureData";
import { ipad_width } from "../util/style";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const MapPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const show_type_list = [
    { kor: "제품 이미지", eng: "image" },
    { kor: "도면", eng: "2D" },
    { kor: "텍스쳐", eng: "Tx" },
    { kor: "3D 모델", eng: "3D" },
  ];
  const [close_data, setCloseData] = useState({ a: false });
  const [show_type, setShowType] = useState("image");
  const map_ref = useRef();
  const [map, setMap] = useState();
  const [material_like_list, setMaterialLikeList] = useState([]);
  const [map_search_list, setMapSearchList] = useState([]);
  const query = useQueryParam();
  const [map_boundary, setMapBoundary] = useState({});
  const [architecture_data, setArchitectureData] = useState([]);
  const [nearby_architecture_data, setNearbyArchitectureData] = useState([]);
  const [selected_material_list, setSelectedMaterialList] = useState([]);
  const [hovered_data, setHoveredData] = useState(null);
  const [map_state, setMapState] = useState(
    global_data.map_state ?? {
      lat: 37.566535,
      lng: 126.9779692,
      level: 4,
    }
  );
  const [got_map_state_data, setGotMapStateData] = useState(false);

  let places = new window.kakao.maps.services.Places();

  const latestRequestTimestamp = useRef(Date.now());

  const searchChangeCallback = (
    result,
    status,
    pagination,
    requestTimestamp
  ) => {
    if (requestTimestamp !== latestRequestTimestamp.current) {
      return; // 요청 시점이 최신이 아닌 경우 무시
    }
    if (status === window.kakao.maps.services.Status.OK) {
      setMapSearchList(
        result.map((e) => ({
          ...e,
          title: e.place_name,
          illust: e.road_address_name,
          illust2: e.address_name,
        }))
      );
    }
  };

  const handleSearchChange = (e) => {
    const requestTimestamp = Date.now();
    latestRequestTimestamp.current = requestTimestamp;
    try {
      places.keywordSearch(
        e,
        (result, status, pagination) =>
          searchChangeCallback(result, status, pagination, requestTimestamp),
        {
          location: map.getCenter(),
        }
      );
    } catch (error) {
      console.error("Error during keyword search", error);
    }
  };

  const handleMapChange = (map) => {
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    setMapBoundary({
      neLat: ne.getLat(),
      neLng: ne.getLng(),
      swLat: sw.getLat(),
      swLng: sw.getLng(),
    });
    const center = map.getCenter();
    setGlobalData({
      map_state: {
        lat: center.getLat(),
        lng: center.getLng(),
        level: map.getLevel(),
      },
    });
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      let options = {
        // //지도를 생성할 때 필요한 기본 옵션
        center: new window.kakao.maps.LatLng(map_state.lat, map_state.lng), //지도의 중심좌표.
        level: map_state.level, //지도의 레벨(확대, 축소 정도)
        mapTypeId: window.kakao.maps.MapTypeId.ROADMAP,
        // projectionId: null,
      };
      setMap(new window.kakao.maps.Map(map_ref.current, options)); //지도 생성 및 객체 리턴
    }, 100);
  }, []);

  useEffect(() => {
    if (map) {
      setTimeout(() => handleMapChange(map), 500);
    }
  }, [map]);

  useEffect(() => {
    if (map && global_data.map_state && !got_map_state_data) {
      setMapState(global_data.map_state);
      map.panTo(
        new window.kakao.maps.LatLng(
          global_data.map_state.lat,
          global_data.map_state.lng
        )
      );
      setTimeout(
        () => map.setLevel(global_data.map_state.level, { animate: true }),
        300
      );

      setGotMapStateData(true);
    }
  }, [map, global_data.map_state]);

  useEffect(() => {
    axios
      .put(
        API_URI + "data",
        getMapPageQueryOfMaterialData(
          global_data?.like_data?.material ?? [],
          global_data.filter?.current,
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) => {
        setMaterialLikeList(convertMaterialDataForMapPage(res.data));
      })
      .catch(console.log);
  }, [
    global_data.filter,
    global_var.data_sort,
    global_data.search_data,
    match?.params?.type,
  ]);

  useEffect(() => {
    axios
      .put(
        API_URI + "data",
        getMapPageQueryOfArchitectureData(
          map_boundary,
          selected_material_list,
          global_var.data_sort
        )
      )
      .then((res) => {
        setArchitectureData(convertArchitectureDataForMapPage(res.data));
      })
      .catch(console.log);
  }, [selected_material_list, map_boundary, global_var.data_sort]);

  useEffect(() => {
    axios
      .put(
        API_URI + "data",
        getMapPageQueryOfArchitectureData(
          map_boundary,
          [],
          global_var.data_sort
        )
      )
      .then((res) => {
        setNearbyArchitectureData(convertArchitectureDataForMapPage(res.data));
      })
      .catch(console.log);
  }, [map_boundary, global_var.data_sort]);

  const [mouse_over, setMouseOver] = useState(false);

  return (
    <>
      <MetaData path="/map" />
      <Header emph="map" />
      <div className={cx("wrapper")}>
        {/* <LoadedImage
          className={cx("background")}
          src="https://t4.ftcdn.net/jpg/02/20/66/39/240_F_220663994_qXlGkI4TV9WkPbraM0tjcTNrVcyLWF9D.jpg"
          duration={0.1}
        ></LoadedImage> */}

        <List gap={0} tight={false}>
          <div className={cx("body")}>
            <List gap={0} tight={false}>
              <div className={cx("frame-title")}>
                <List gap={0} align="left">
                  <div className={cx("text-title")}>지도로 사례보기</div>
                  <div className={cx("text-illust")}>
                    내가 원하는 자재가 실제로 어디에 적용되었는지 지도에서
                    둘러보아요.
                  </div>
                </List>
              </div>
              <Filter
                show_properties={["type"]}
                show_dropdown={["sort"]}
                onSearch={(e) => {
                  window.history.replaceState({}, "", "/map?search=" + e);
                }}
              />
              <div className={cx("frame-content")}>
                <List
                  type={global_var.window_width < ipad_width ? "column" : "row"}
                  gap={1}
                  tight={false}
                  fillY
                >
                  <motion.div
                    className={cx("frame-map")}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      delay: 0.1,
                      duration: 0.5,
                      type: "spring",
                      stiffness: 100,
                    }}
                    ref={map_ref}
                  >
                    <KakaoMapProvider map={map} onChange={handleMapChange}>
                      {architecture_data.map((e) => (
                        <MapPicker
                          {...e}
                          id={e.architecture_id}
                          material_list={[
                            ...e.used_materials
                              .filter((e2) =>
                                selected_material_list.includes(e2.id)
                              )
                              .map((e2) => ({ title: e2.name, clicked: true })),
                            ...e.used_materials
                              .filter(
                                (e2) => !selected_material_list.includes(e2.id)
                              )
                              .map((e2) => ({
                                title: e2.name,
                                clicked: false,
                              })),
                          ]}
                          illust2={
                            e.illust2 +
                            (e.address.is_exact ? "" : "(위치 부정확)")
                          }
                          exact={e.address.is_exact}
                        />
                      ))}
                      {hovered_data && (
                        <MapPicker
                          {...hovered_data}
                          id={hovered_data.architecture_id}
                          material_list={[
                            ...hovered_data.used_materials
                              .filter((e2) =>
                                selected_material_list.includes(e2.id)
                              )
                              .map((e2) => ({ title: e2.name, clicked: true })),
                            ...hovered_data.used_materials
                              .filter(
                                (e2) => !selected_material_list.includes(e2.id)
                              )
                              .map((e2) => ({
                                title: e2.name,
                                clicked: false,
                              })),
                          ]}
                          force_hover
                          illust2={
                            hovered_data.illust2 +
                            (hovered_data.address.is_exact
                              ? ""
                              : " (위치 부정확)")
                          }
                          exact={hovered_data.address.is_exact}
                        />
                      )}
                    </KakaoMapProvider>
                    {architecture_data.length === 0 && (
                      <div className={cx("frame-map-overlay")}>
                        <List
                          attach="center"
                          align="center"
                          tight={false}
                          fillY
                        >
                          <motion.div
                            className={cx("text-error", "big")}
                            initial={{ opacity: 0.5 }}
                            animate={{ opacity: 1 }}
                            transition={{
                              repeat: Infinity,
                              repeatType: "reverse",
                              ease: "easeInOut",
                              duration: 1.5,
                            }}
                          >
                            <div>현재 지도 영역에서</div>
                            <div>선택한 건축자재를 가진 사례가 없어요.</div>
                            <div>지도를 이동, 축소하거나</div>
                            <div>다른 건축자재를 선택해보세요.</div>
                          </motion.div>
                        </List>
                      </div>
                    )}
                    <div className={cx("frame-map-overlay")}>
                      <List
                        type="row"
                        padding={0.5}
                        gap={0.5}
                        attach="end"
                        align="left"
                        tight={false}
                        multiple_line
                      >
                        <Search
                          id="map_address"
                          color="white"
                          placeholder="주소 검색하기"
                          search_list={map_search_list}
                          onChange={handleSearchChange}
                          onClick={(e) => {
                            if (e.x && e.y) {
                              map.panTo(
                                new window.kakao.maps.LatLng(
                                  Number(e.y),
                                  Number(e.x)
                                )
                              );
                              setTimeout(
                                () => map.setLevel(4, { animate: true }),
                                300
                              );
                            }
                          }}
                        ></Search>
                      </List>
                    </div>
                  </motion.div>
                  <div className={cx("frame-info")}>
                    <List gap={1} tight={false}>
                      <motion.div
                        className={cx("frame-div")}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          delay: 0.2,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <List padding={0.5} gap={0.5} tight={false}>
                          <div className={cx("text-section")}>
                            내가 좋아하는 건축자재
                          </div>
                          <div className={cx("frame-scroll")}>
                            <List
                              padding={0.25}
                              gap={0.5}
                              align="left"
                              tight={false}
                            >
                              {[
                                ...new Set(
                                  material_like_list.reduce(
                                    (prev, curr) => [
                                      ...prev,
                                      curr?.filter?.type,
                                    ],
                                    []
                                  )
                                ),
                              ].map((key, idx) => (
                                <List
                                  gap={0.25}
                                  key={idx}
                                  align="left"
                                  tight={false}
                                  style={{ height: "none" }}
                                >
                                  <div
                                    className={cx("frame-subsection")}
                                    onClick={() => {
                                      setCloseData((e) => {
                                        const new_e = { ...e };
                                        new_e[key] = !(e[key] ?? false);
                                        return new_e;
                                      });
                                    }}
                                  >
                                    <List
                                      type="row"
                                      attach="space"
                                      tight={false}
                                    >
                                      <div className={cx("text-subsection")}>
                                        {key}
                                      </div>
                                      <div
                                        className={cx(
                                          "frame-icon",
                                          close_data[key] ? "" : "close"
                                        )}
                                      >
                                        <IconDropDown />
                                      </div>
                                    </List>
                                  </div>
                                  <motion.div
                                    className={cx("frame-dropdown")}
                                    animate={{
                                      height: close_data[key] ? 0 : "100%",
                                      opacity: close_data[key] ? 0 : 1,
                                    }}
                                  >
                                    <List
                                      type="row"
                                      gap={0.25}
                                      tight={false}
                                      multiple_line
                                    >
                                      {material_like_list
                                        .filter((e) => e.filter?.type === key)
                                        .map((data, idx) => (
                                          <TableCard
                                            key={idx}
                                            {...data}
                                            type="small"
                                            show_like={false}
                                            clicked={selected_material_list.includes(
                                              data.id
                                            )}
                                            onClick={() => {
                                              if (
                                                selected_material_list.includes(
                                                  data.id
                                                )
                                              ) {
                                                setSelectedMaterialList(
                                                  (list) =>
                                                    list.filter(
                                                      (e) => e !== data.id
                                                    )
                                                );
                                              } else {
                                                setSelectedMaterialList(
                                                  (list) => [...list, data.id]
                                                );
                                              }
                                            }}
                                          />
                                        ))}
                                    </List>
                                  </motion.div>
                                </List>
                              ))}
                              {material_like_list.length === 0 && (
                                <motion.div
                                  className={cx("text-error")}
                                  initial={{ opacity: 0.3 }}
                                  animate={{ opacity: 0.7 }}
                                  transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut",
                                    duration: 1.5,
                                  }}
                                >
                                  <div>좋아하는 건축자재가 없어요.</div>
                                  <div>검색 필터를 바꾸어보거나</div>
                                  <div>좋아하는 자재를 추가해봐요.</div>
                                </motion.div>
                              )}
                            </List>
                          </div>
                        </List>
                      </motion.div>
                      <motion.div
                        className={cx("frame-div")}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          delay: 0.3,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <List padding={0.5} gap={0.5} tight={false}>
                          <div className={cx("text-section")}>
                            등록된 건축 사례
                          </div>
                          <div className={cx("frame-scroll")}>
                            <List gap={0.25} tight={false}>
                              {nearby_architecture_data.map((e, idx) => (
                                <Link
                                  key={idx}
                                  to={"/architecture/" + e.architecture_id}
                                  style={{ width: "100%" }}
                                >
                                  <TableCard
                                    {...e}
                                    id={e.architecture_id}
                                    collection="architecture"
                                    onMouseEnter={
                                      global_var.media_mobile
                                        ? undefined
                                        : () => setHoveredData(e)
                                    }
                                    onMouseLeave={
                                      global_var.media_mobile
                                        ? undefined
                                        : () => setHoveredData(null)
                                    }
                                    sensitive
                                    illust2={
                                      e.illust2 +
                                      (e.address.is_exact
                                        ? ""
                                        : " (위치 부정확)")
                                    }
                                  ></TableCard>
                                </Link>
                              ))}

                              {nearby_architecture_data.length === 0 && (
                                <motion.div
                                  className={cx("text-error")}
                                  initial={{ opacity: 0.3 }}
                                  animate={{ opacity: 0.7 }}
                                  transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut",
                                    duration: 1.5,
                                  }}
                                >
                                  <div>
                                    현재 지도 영역에 건축 사례가 없어요.
                                  </div>
                                  <div>등록된 건축 사례를 보려면</div>
                                  <div>지도를 이동하거나 축소해봐요.</div>
                                </motion.div>
                              )}
                            </List>
                          </div>
                        </List>
                      </motion.div>
                    </List>
                  </div>
                </List>
              </div>
            </List>
          </div>
        </List>
      </div>
    </>
  );
};

export default MapPage;
