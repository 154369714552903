import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { IP_URI, API_URI } from "./shortcut";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const setVhAndVw = () => {
  let vh = window.innerHeight * 0.01;
  let vw = window.innerWidth * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");
  document.documentElement.style.setProperty("--100vh", 100 * vh + "px");
  document.documentElement.style.setProperty("--newvh", vh + "px");
  document.documentElement.style.setProperty("--new100vh", 100 * vh + "px");
  document.documentElement.style.setProperty("--vw", vw + "px");
  document.documentElement.style.setProperty("--100vw", 100 * vw + "px");
  document.documentElement.style.setProperty("--newvw", vw + "px");
  document.documentElement.style.setProperty("--new100vw", 100 * vw + "px");
};

setVhAndVw();

// axios.get(API_URI).then((res, req) => {
//   console.log(res);
// });

window.addEventListener("resize", () => {
  setVhAndVw();
});

window.addEventListener("scroll", () => {
  setVhAndVw();
});

window.addEventListener("locationchange", () => {
  setVhAndVw();
});

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
