import React, { Children, useState, useMemo } from "react";
// { useEffect }

import styles from "./PageNoList.module.scss";
import classNames from "classnames/bind";

import { ReactComponent as IconLeft } from "../svgs/IconLeft.svg";
import { ReactComponent as IconRight } from "../svgs/IconRight.svg";
import List from "../atoms/List";

const cx = classNames.bind(styles);

const PageNoList = ({
  item_count = 0,
  item_num = 11,
  initial_emph_no = 1,
  show_num = 9,
  onClick = (e) => {
    console.log("clicked pagenolist component : " + e);
  },
}) => {
  // 아래에 개별 아이콘 호출어 추가
  const [emph_no, setEmphNo] = useState(initial_emph_no);
  const [mouse_over, setMouseOver] = useState(false);
  const page_no_list = useMemo(() => {
    const min_no = Math.round(show_num / 2);
    const max_no =
      Math.ceil(item_count / item_num) - Math.round(show_num / 2) + 1;
    if (emph_no > Math.ceil(item_count / item_num)) {
      setEmphNo(1);
      onClick(1);
      return [];
    }
    if (emph_no < min_no) {
      return new Array(show_num)
        .fill(0)
        .map((_, idx) => idx + 1)
        .filter((e) => e <= Math.ceil(item_count / item_num));
    }
    if (emph_no > max_no) {
      return new Array(show_num)
        .fill(0)
        .map((_, idx) => Math.ceil(item_count / item_num) - idx)
        .reverse()
        .filter((e) => e > 0 && e <= Math.ceil(item_count / item_num));
    }
    return new Array(show_num)
      .fill(0)
      .map((_, idx) => emph_no + idx - Math.round(show_num / 2) + 1)
      .filter((e) => e <= Math.ceil(item_count / item_num));
  }, [item_count, item_num, emph_no, show_num]);
  return (
    <div className={cx("wrapper")}>
      <List type="row" gap={0.25}>
        <IconLeft
          onClick={() => {
            onClick(Math.max(emph_no - 1, 1));
            setEmphNo((e) => Math.max(e - 1, 1));
          }}
        />
        {page_no_list.map((e, idx) => (
          <div
            className={cx("frame-no", e == emph_no ? "emph" : "")}
            onClick={() => {
              setEmphNo(e);
              onClick(e, idx);
            }}
            key={e}
          >
            {e}
          </div>
        ))}
        <IconRight
          onClick={() => {
            onClick(Math.min(emph_no + 1, Math.ceil(item_count / item_num)));
            setEmphNo((e) => Math.min(e + 1, Math.ceil(item_count / item_num)));
          }}
        />
      </List>
    </div>
  );
};

export default PageNoList;
