import React, {
  useEffect,
  useReducer,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./BrandIllustPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";
import Like from "../component/Like";
import Button from "../atoms/Button";
import {
  convertBrandDataForBrandIllustPage,
  getBrandIllustPageQueryOfBrandData,
} from "../functions/handleBrandData";
import axios from "axios";
import { API_URI } from "../shortcut";
import Chip from "../atoms/Chip";
import {
  convertMaterialDataForBrandIllustPage,
  getBrandIllustPageCountQueryOfMaterialData,
  getBrandIllustPageQueryOfMaterialData,
  item_num,
} from "../functions/handleMaterialData";
import PageNoList from "../component/PageNoList";
import useQueryParam from "../hooks/useQueryParam";
import LikeAutomatedById from "../component/LikeAutomatedById";
import { ipad_width } from "../util/style";
import Popup from "../component/Popup";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const BrandIllustPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [brand_data, setBrandData] = useState({});
  const [item_loaded, setItemLoaded] = useState(false);
  const [item_list, setItemList] = useState([]);
  const [item_count, setItemCount] = useState(0);
  const [page_no, setPageNo] = useState(1);

  const query = useQueryParam();
  const history = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    axios
      .put(
        API_URI + "data",
        getBrandIllustPageQueryOfBrandData(match?.params?.id)
      )
      .then((res) => {
        const page_illust_data = convertBrandDataForBrandIllustPage(res.data);
        setBrandData(page_illust_data);
      })
      .catch(console.log);
  }, [match]);

  useLayoutEffect(() => {
    if (brand_data.brand_id) {
      setItemLoaded(false);
      axios
        .put(
          API_URI + "data",
          getBrandIllustPageQueryOfMaterialData(
            brand_data.brand_id,
            [],
            [],
            global_var.data_sort,
            page_no,
            item_num,
            global_data.search_data?.filter?.current ||
              (query.get("search") ?? "")
          )
        )
        .then((res) => {
          setItemList(convertMaterialDataForBrandIllustPage(res.data));
          setItemLoaded(true);
        })
        .catch(console.log);
      axios
        .put(
          API_URI + "data",
          getBrandIllustPageCountQueryOfMaterialData(
            brand_data.brand_id,
            global_data.filter?.current,
            global_var.data_show,
            global_var.data_sort,
            global_data.search_data?.filter?.current ||
              (query.get("search") ?? "")
          )
        )
        .then((res) => setItemCount(res.data?.[0]?.totalCount))
        .catch(console.log);
    }
  }, [
    brand_data,
    global_var.data_show,
    global_var.data_sort,
    page_no,
    global_data.search_data,
  ]);

  return (
    <>
      <MetaData path="/brand" />
      <Header emph="brand" />
      <div className={cx("wrapper")}>
        <List gap={0}>
          <div className={cx("body")}>
            <List gap={0} tight={false}>
              <List gap={0} tight={false}>
                <div className={cx("frame-title")}>
                  <List
                    type={
                      global_var.window_width < ipad_width ? "column" : "row"
                    }
                    align={
                      global_var.window_width < ipad_width ? "left" : "center"
                    }
                    attach="space"
                    gap={1}
                  >
                    <List gap={0.25} align="left">
                      <List gap={0} align="left">
                        <div className={cx("text-title")}>
                          {brand_data.title +
                            (brand_data.brand_type == "유통사"
                              ? "[유통사]"
                              : "")}
                        </div>
                        <div className={cx("text-illust")}>
                          사이트 주소 : {brand_data.url}
                        </div>
                        <div className={cx("text-illust")}>
                          {brand_data.site_title}
                        </div>
                        <div className={cx("text-illust")}>
                          {brand_data.illust}
                        </div>
                      </List>
                      {brand_data.name_alt?.length == 0 ? (
                        <></>
                      ) : (
                        <List
                          type="row"
                          align="center"
                          gap={0.375}
                          tight={false}
                          multiple_line
                        >
                          {brand_data.name_alt?.map((e, idx) => (
                            <Chip clicked key={idx}>
                              {e}
                            </Chip>
                          ))}
                        </List>
                      )}
                    </List>
                    <List
                      type={
                        global_var.window_width < ipad_width
                          ? "row-reverse"
                          : "row"
                      }
                      align="center"
                      gap={1}
                    >
                      <LikeAutomatedById
                        like_num={brand_data.like_num}
                        collection="brand"
                        id={brand_data.brand_id}
                        show_num
                      />
                      <Button
                        color="brown-light"
                        onClick={() =>
                          window.open(brand_data.url, brand_data.name)
                        }
                        GTM={"BrandIllustPage_사이트_방문하기"}
                      >
                        사이트 방문하기
                      </Button>
                    </List>
                  </List>
                </div>
                <div className={cx("frame-section")}>
                  {brand_data.title}의 건축자재 살펴보기
                </div>
                <Filter
                  no_filter
                  onSearch={(e) => {
                    window.history.replaceState(
                      {},
                      "",
                      "/brand/" + match?.params?.id + "?search=" + e
                    );
                  }}
                />
                <ItemList
                  type={global_var.data_show}
                  item_list={item_list}
                  no_data_alert="이 브랜드의 자재 데이터가 모여있지 않아요. 사이트로 직접 방문해보세요."
                  no_searched_data_alert="검색 결과가 없어요. 다시 한번 검색해보세요."
                />
                <div className={cx("frame-space")}></div>
                <PageNoList
                  item_count={item_count}
                  item_num={item_num}
                  onClick={(e) => setPageNo(e)}
                ></PageNoList>
                <div className={cx("frame-space")}></div>
                <div className={cx("frame-space")}></div>
                <Button
                  type="brown-light"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  맨 위로 올라가기
                </Button>
                <div className={cx("frame-space")}></div>
                <div className={cx("frame-space")}></div>
              </List>
            </List>
          </div>
        </List>
      </div>
      <Popup use_backdrop onClose={() => setGlobalData({ popup_data: null })}>
        {item_loaded &&
          item_list.length === 0 &&
          (global_data.search_data?.filter?.current || "") === "" &&
          (query.get("search") ?? "") == "" && (
            <div className={cx("frame-popup")}>
              <List align="center" gap={2}>
                <List align="center" gap={1}>
                  <div className={cx("text-popup-title")}>
                    해당 브랜드의 자재 데이터가 없어요.
                  </div>
                  <List align="center" fillX>
                    <div className={cx("text-popup")}>
                      현재 서비스 준비중으로 일부 타일, 벽돌, 창호 자재 데이터만
                      모여 있어요.
                    </div>
                    <div className={cx("text-popup")}>
                      해당 브랜드의 사이트로 바로 연결할까요,
                    </div>
                    <div className={cx("text-popup")}>
                      아니면 다른 브랜드를 선택해보시겠어요?
                    </div>
                  </List>
                </List>
                <List attach="center" type="row" fillX gap={0.5} multiple_line>
                  <Button
                    type="green-light"
                    onClick={() => {
                      window.open(brand_data?.url, brand_data?.brand_id);
                    }}
                  >
                    {brand_data?.name} 사이트 방문하기
                  </Button>
                  <Button
                    type="brown-light"
                    onClick={() => {
                      if (location.state?.from === "brand") {
                        history.goBack();
                      } else {
                        history.push("/brand");
                      }
                    }}
                  >
                    다른 브랜드 살펴보기
                  </Button>
                </List>
              </List>
            </div>
          )}
      </Popup>
    </>
  );
};

export default BrandIllustPage;
