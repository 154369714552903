import React, {
  useEffect,
  useReducer,
  useState,
  Suspense,
  useRef,
  useMemo,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./RAQPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";
import Button from "../atoms/Button";
import Like from "../component/Like";
import Chip from "../atoms/Chip";
import Switch from "../atoms/Switch";
import LoadedImage from "../atoms/LoadedImage";
import { motion } from "framer-motion";
import TableCard from "../component/TableCard";
import Search from "../atoms/Search";
import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import { ReactComponent as IconDropDown } from "../svgs/IconDropDown.svg";
import { ReactComponent as IconClose } from "../svgs/IconClose.svg";
import {
  convertMaterialDataForRAQPage,
  getRAQPageQueryOfMaterialData,
} from "../functions/handleMaterialData";
import useQueryParam from "../hooks/useQueryParam";
import axios from "axios";
import { API_URI } from "../shortcut";
import { _deepcopy } from "../util/alias";
import DropDown from "../atoms/DropDown";
import AlertPopupScienario from "../component/AlertPopupScienario";
import { ipad_width } from "../util/style";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const RAQPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [close_data, setCloseData] = useState({});

  const [material_like_list, setMaterialLikeList] = useState([]);
  const [alert_popup_data, setAlertPopupData] = useState({});

  const query = useQueryParam();
  const box_ref = useRef();

  useEffect(() => {
    axios
      .put(
        API_URI + "data",
        getRAQPageQueryOfMaterialData(
          global_data?.like_data?.material ?? [],
          global_data.filter?.current,
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) => {
        setMaterialLikeList(convertMaterialDataForRAQPage(res.data));
      })
      .catch(console.log);
  }, [
    global_data.filter,
    global_var.data_sort,
    global_data.search_data,
    match?.params?.type,
  ]);

  const box_width = useMemo(
    () =>
      (box_ref.current?.clientWidth > 600
        ? (box_ref.current?.clientWidth - 2 * 8) / 3 - 1
        : box_ref.current?.clientWidth > 400
        ? (box_ref.current?.clientWidth - 1 * 8) / 2 - 1
        : box_ref.current?.clientWidth) + "px",
    [box_ref.current?.clientWidth]
  );

  return (
    <>
      <MetaData path="/RAQ" />
      <Header emph="RAQ" />
      <div className={cx("wrapper")}>
        {/* <LoadedImage
          className={cx("background")}
          src="https://t4.ftcdn.net/jpg/02/20/66/39/240_F_220663994_qXlGkI4TV9WkPbraM0tjcTNrVcyLWF9D.jpg"
          duration={0.1}
        ></LoadedImage> */}

        <List gap={0} tight={false}>
          <div className={cx("body")}>
            <List tight={false}>
              <div className={cx("frame-title")}>
                <List type="row" align="center" attach="space" gap={1}>
                  <List gap={0} align="left">
                    <div className={cx("text-title")}>견적 일괄 요청하기</div>
                    <div className={cx("text-illust")}>
                      한번에 견적을 알아보세요.
                    </div>
                  </List>
                </List>
              </div>
              <Filter
                show_dropdown={["sort"]}
                onSearch={(e) => {
                  window.history.replaceState({}, "", "/RAQ?search=" + e);
                }}
              />
              <div className={cx("frame-content")}>
                <List
                  type={global_var.window_width < ipad_width ? "column" : "row"}
                  gap={1}
                  tight={false}
                  fillY
                >
                  <motion.div
                    className={cx("frame-image")}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 1 }}
                    transition={{
                      delay: 0,
                      duration: 0.5,
                      type: "spring",
                      stiffness: 100,
                    }}
                  >
                    <List padding={0.5} gap={0.5} tight={false}>
                      <div className={cx("text-section")}>
                        좋아하는 건축자재
                      </div>
                      <div className={cx("frame-scroll")}>
                        <List padding={0.25} gap={1} align="left" tight={false}>
                          {[
                            ...new Set(
                              material_like_list.reduce(
                                (prev, curr) => [...prev, curr?.filter?.type],
                                []
                              )
                            ),
                          ].map((key, idx) => (
                            <List
                              gap={0.25}
                              key={idx}
                              align="left"
                              tight={false}
                              style={{ height: "none" }}
                            >
                              <div
                                className={cx("frame-subsection")}
                                onClick={() => {
                                  setCloseData((e) => {
                                    const new_e = { ...e };
                                    new_e[key] = !(e[key] ?? false);
                                    return new_e;
                                  });
                                }}
                              >
                                <List type="row" attach="space" tight={false}>
                                  <div className={cx("text-subsection")}>
                                    {key}
                                  </div>
                                  <div
                                    className={cx(
                                      "frame-icon",
                                      close_data[key] ? "" : "close"
                                    )}
                                  >
                                    <IconDropDown />
                                  </div>
                                </List>
                              </div>
                              <motion.div
                                className={cx("frame-dropdown")}
                                animate={{
                                  height: close_data[key] ? 0 : "100%",
                                  opacity: close_data[key] ? 0 : 1,
                                }}
                                ref={idx == 0 ? box_ref : null}
                              >
                                <List gap={0.25} tight={false} align="left">
                                  <List
                                    type="row"
                                    gap={0.5}
                                    tight={false}
                                    multiple_line
                                  >
                                    {material_like_list
                                      .filter((e) => e.filter?.type === key)
                                      .map((e, idx) => (
                                        <div
                                          className={cx("frame-selection")}
                                          key={idx}
                                          style={{
                                            width: box_width,
                                          }}
                                        >
                                          <TableCard
                                            {...e}
                                            show_like={false}
                                            clicked={global_data.RAQ_list?.map(
                                              (e) => e.id
                                            )?.includes(e.id)}
                                            onClick={() => {
                                              global_data.RAQ_list?.map(
                                                (rfq) => rfq.id
                                              )?.includes(e.id)
                                                ? setGlobalData((data) => ({
                                                    RAQ_list:
                                                      data.RAQ_list?.filter(
                                                        (rfq) => rfq.id !== e.id
                                                      ) ?? [],
                                                  }))
                                                : setGlobalData((data) => {
                                                    const RAQ_list =
                                                      _deepcopy(
                                                        data.RAQ_list
                                                      ) ?? [];
                                                    RAQ_list.push({
                                                      id: e.id,
                                                      brand:
                                                        e.brand
                                                          ?.distribution_brand
                                                          ?.name ||
                                                        e.brand?.original_brand
                                                          ?.name,
                                                      brand_id:
                                                        e.brand
                                                          ?.distribution_brand
                                                          ?.id ||
                                                        e.brand?.original_brand
                                                          ?.id,
                                                      data: e,
                                                      size: 100,
                                                    });
                                                    return { RAQ_list };
                                                  });
                                            }}
                                          />
                                        </div>
                                      ))}
                                  </List>
                                </List>
                              </motion.div>
                            </List>
                          ))}
                          {material_like_list.length === 0 && (
                            <motion.div
                              className={cx("text-error")}
                              initial={{ opacity: 0.3 }}
                              animate={{ opacity: 0.7 }}
                              transition={{
                                repeat: Infinity,
                                repeatType: "reverse",
                                ease: "easeInOut",
                                duration: 1.5,
                              }}
                            >
                              <div>좋아하는 건축자재가 없어요.</div>
                              <div>검색 필터를 수정하거나</div>
                              <div>좋아하는 자재를 추가해봐요.</div>
                            </motion.div>
                          )}
                        </List>
                      </div>
                    </List>
                  </motion.div>

                  <div className={cx("frame-info")}>
                    <List gap={1} tight={false}>
                      <motion.div
                        className={cx("frame-div")}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 1 }}
                        transition={{
                          delay: 0.1,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <List padding={0.5} gap={0.5} tight={false}>
                          <List
                            type="row"
                            align="end"
                            attach="space"
                            tight={false}
                          >
                            <List type="row" align="end">
                              <div className={cx("text-section", "tight")}>
                                요청항목
                              </div>
                              <div
                                className={cx(
                                  "text-section",
                                  "tight",
                                  "amount"
                                )}
                              >
                                {global_data?.RAQ_list?.length ?? 0}
                              </div>
                            </List>
                            <div className={cx("text-section", "tight")}>
                              수량
                            </div>
                          </List>
                          <div className={cx("frame-scroll")}>
                            <List
                              gap={1}
                              align="left"
                              tight={false}
                              style={{ height: "none" }}
                            >
                              {[
                                ...new Set(
                                  global_data.RAQ_list?.reduce(
                                    (prev, curr) => [...prev, curr.brand],
                                    []
                                  ) ?? []
                                ),
                              ].map((key, idx) => (
                                <List
                                  gap={0.25}
                                  key={idx}
                                  align="left"
                                  tight={false}
                                  style={{ height: "none" }}
                                >
                                  <div
                                    className={cx("frame-subsection")}
                                    onClick={() => {
                                      setCloseData((e) => {
                                        const new_e = { ...e };
                                        new_e[key] = !(e[key] ?? false);
                                        return new_e;
                                      });
                                    }}
                                  >
                                    <List
                                      type="row"
                                      attach="space"
                                      tight={false}
                                    >
                                      <div className={cx("text-subsection")}>
                                        {key}
                                      </div>
                                      <div
                                        className={cx(
                                          "frame-icon",
                                          close_data[key] ? "" : "close"
                                        )}
                                      >
                                        <IconDropDown />
                                      </div>
                                    </List>
                                  </div>
                                  <motion.div
                                    className={cx("frame-dropdown")}
                                    animate={{
                                      height: close_data[key] ? 0 : "100%",
                                      opacity: close_data[key] ? 0 : 1,
                                    }}
                                  >
                                    <List gap={0.25} tight={false} align="left">
                                      <List tight={false}>
                                        {(
                                          global_data.RAQ_list?.filter(
                                            (e) => e.brand == key
                                          ) ?? []
                                        ).map((e, idx) => (
                                          <List
                                            key={idx}
                                            type="row"
                                            gap={0.25}
                                            attach="space"
                                            tight={false}
                                          >
                                            <List
                                              key={idx}
                                              type="row"
                                              tight={false}
                                              style={{ minWidth: 0 }}
                                            >
                                              <TableCard
                                                type="small"
                                                {...e.data}
                                                clickable={false}
                                                show_like={false}
                                              />
                                              <div
                                                className={cx("frame-delete")}
                                                onClick={() =>
                                                  setGlobalData((data) => ({
                                                    RAQ_list:
                                                      data.RAQ_list?.filter(
                                                        (rfq) => rfq.id !== e.id
                                                      ),
                                                  }))
                                                }
                                              >
                                                <IconClose />
                                              </div>
                                            </List>
                                            <List padding={0.5} type="row">
                                              <input
                                                className={cx(
                                                  "text-amount",
                                                  "input"
                                                )}
                                                placeholder={e.size}
                                                onBlur={(event) =>
                                                  setGlobalData((data) => ({
                                                    RAQ_list: data.RAQ_list.map(
                                                      (rfq) =>
                                                        rfq.id === e.id &&
                                                        event.target.value?.replaceAll(
                                                          " ",
                                                          ""
                                                        ).length > 0
                                                          ? {
                                                              ...rfq,
                                                              size: event.target
                                                                .value,
                                                            }
                                                          : rfq
                                                    ),
                                                  }))
                                                }
                                                type="number"
                                                inputmode="numeric"
                                                pattern="[0-9]*"
                                              />
                                              <div
                                                className={cx("text-amount")}
                                              >
                                                장
                                              </div>
                                            </List>
                                          </List>
                                        ))}
                                      </List>
                                    </List>
                                  </motion.div>
                                </List>
                              ))}
                              {(global_data.RAQ_list?.length ?? 0) === 0 && (
                                <motion.div
                                  className={cx("text-error")}
                                  initial={{ opacity: 0.3 }}
                                  animate={{ opacity: 0.7 }}
                                  transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    ease: "easeInOut",
                                    duration: 1.5,
                                  }}
                                >
                                  <div>선택한 건축자재가 없어요.</div>
                                  <div>왼쪽에서 견적을 요청할</div>
                                  <div>건축자재를 선택해봐요.</div>
                                </motion.div>
                              )}
                            </List>
                          </div>
                          <Button
                            color="brown-light"
                            fillX
                            style={{ flexShrink: 0 }}
                            onClick={() =>
                              setAlertPopupData({
                                data: "견적메일 요청",
                              })
                            }
                            GTM={"RAQPage_견적요청"}
                          >
                            견적메일 요청하기
                          </Button>
                        </List>
                      </motion.div>
                    </List>
                  </div>
                </List>
              </div>
            </List>
          </div>
        </List>
        <AlertPopupScienario
          popup_data={alert_popup_data}
          setPopupData={setAlertPopupData}
        />
      </div>
    </>
  );
};

export default RAQPage;
