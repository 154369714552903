import { createContext, useContext, useMemo, useEffect } from "react";

export const KakaoMapContext = createContext();

const useKakaoMap = (component_name) => {
  const kakao_map = useContext(KakaoMapContext);
  if (!kakao_map) {
    throw new Error(
      (component_name ? component_name + " Component" : "useMap") +
        "must exist inside Map Component."
    );
  }
  return kakao_map;
};

export const KakaoMapProvider = ({ children, map, onChange }) => {
  useEffect(() => {
    if (map && onChange) {
      const listener = window.kakao.maps.event.addListener(map, "idle", (e) =>
        onChange(map, e)
      );
      return () => {
        if (listener) {
          window.kakao.maps.event.removeListener(listener);
        }
      };
    }
  }, [map, onChange]);

  return (
    map && (
      <KakaoMapContext.Provider value={map}>
        {children}
      </KakaoMapContext.Provider>
    )
  );
};

export default useKakaoMap;
