export const MATERIAL_TYPE = [
  "창호",
  "타일",
  "벽돌",
  "주방",
  "수전·도기",
  "조명",
  "유리",
  "문",
  "하드웨어",
  "콘센트",
  "석재",
  "미장",
  "페인트",
  "차양",
  "마루",
  "트렌치",
  "엘리베이터",
  "에스컬레이터",
  "큐비클",
  "천장재",
  "난간",
  "금속",
  "특수제품",
];

export const LOCATION_TYPE = [
  "바닥",
  "외부",
  "내부",
  "벽",
  "현관",
  "발코니",
  "욕실",
  "주방",
];

export const SPEC_TYPE = [
  "방음",
  "차음",
  "방화",
  "내화",
  "방수",
  "방습",
  "단열",
  "기밀",
  "내진",
  "면진",
];

export const BRAND_TYPE = ["원브랜드", "유통사"];
