import React, { useState, useEffect, useRef } from "react";
// { useEffect }

import styles from "./NavCard.module.scss";
import classNames from "classnames/bind";
import Tooltip from "../atoms/Tooltip";
import List from "../atoms/List";
import Card from "../atoms/Card";
import Divider from "../atoms/Divider";
import Input from "./Input";
import SummaryCard from "./SummaryCard";
import DataCard from "./DataCard";
import Button from "../atoms/Button";
import { _transformScroll } from "../util/alias";
import useGlobalVar from "../hooks/useGlobalVar";
import { encodeNumberList } from "../util/encodeData";
import { SERVICE_URI } from "../shortcut";
import useGlobalData from "../hooks/useGlobalData";
import Chip from "../atoms/Chip";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

const NavCard = ({
  src = "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  title = "title",
  illust = "illust",
  link_to = "/material",
  small = false,
}) => {
  const [mouse_over, setMouseOver] = useState(false);

  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();

  return (
    <Link to={link_to}>
      <div className={cx("wrapper")}>
        <List align="left" gap={0.5} padding={1} tight={false}>
          <List type="row" align="right" tight={false}>
            <div className={cx("frame-icon", small ? "small" : "")}>
              <img
                className={cx("image")}
                src={src}
                // style={{ scale: 0.25 }}
              ></img>
            </div>
          </List>
          <List align="left" gap={0.125}>
            <div className={cx("text-title")}>{title}</div>
            <div className={cx("text-illust")}>{illust}</div>
          </List>
        </List>
      </div>
    </Link>
  );
};

export default NavCard;

// ### Card

// - shape: default / rectangle
// - children: any
// - padding: int
// - clickable: boolean
// - transparent: boolean
// - onClick: ()=>any
// - use_tooltip: boolean
// - tooltip: [any]
// - tight: boolean
