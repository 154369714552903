import React, { useEffect, useReducer, useState } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./SpecialPage.module.scss";

import "react-notion-x/src/styles.css";
// import { NotionAPI } from "notion-client";
import { NotionRenderer } from "react-notion-x";
import { Code } from "react-notion-x/build/third-party/code";
import { Collection } from "react-notion-x/build/third-party/collection";
import { Equation } from "react-notion-x/build/third-party/equation";
import { Modal } from "react-notion-x/build/third-party/modal";
import { Pdf } from "react-notion-x/build/third-party/pdf";

import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import axios from "axios";
import { API_URI } from "../shortcut";
import {
  convertMaterialPageData,
  getMaterialPageCountQuery,
  getMaterialPageQuery,
  item_num,
} from "../functions/handleMaterialData";

import useQueryParam from "../hooks/useQueryParam";
import MetaData from "./MetaData";
import CustomLink from "../component/CustomLink";

// const notion = new NotionAPI();
// const recordMap = await notion.getPage("");

const cx = classNames.bind(styles);

const NotionLink = ({ href, children, ...props }) => {
  useEffect(() => {
    console.log(href);
  }, []);
  if (href.startsWith("/")) {
    // 내부 링크는 React Router의 Link 컴포넌트를 사용
    return (
      <CustomLink to={href} {...props}>
        {children}
      </CustomLink>
    );
  }

  // 외부 링크는 기본 a 태그를 사용
  return (
    <a href={href} {...props}>
      {children}
    </a>
  );
};

const SpecialPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [item_list, setItemList] = useState([]);
  const [item_count, setItemCount] = useState(0);
  const [page_no, setPageNo] = useState(1);
  const [data_loaded, setDataLoaded] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    axios
      .put(API_URI + "notion", {
        id: match?.params?.id ?? "_-21e4610084704fb3b763b1aa2bf8c318",
      })
      .then((res) => setResponse(res.data))
      .catch(() => {
        window.location.href = "/notfound";
      });
  }, []);

  const query = useQueryParam();

  useEffect(() => {
    setDataLoaded(false);
    axios
      .put(
        API_URI + "data",
        getMaterialPageCountQuery(
          global_data.filter?.current,
          global_var.data_show,
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) => {
        setItemCount(res.data?.[0]?.totalCount);
        axios
          .put(
            API_URI + "data",
            getMaterialPageQuery(
              global_data.filter?.current,
              global_var.data_show,
              global_var.data_sort,
              page_no,
              item_num,
              global_data.search_data?.filter?.current ||
                (query.get("search") ?? "")
            )
          )
          .then((res) => {
            setItemList(convertMaterialPageData(res.data));
            setDataLoaded(true);
          })
          .catch(console.log);
      })
      .catch(console.log);
  }, [
    global_data.filter,
    global_var.data_sort,
    global_var.data_show,
    page_no,
    global_data.search_data,
  ]);

  return (
    <>
      <MetaData path="/special" />
      <Header emph="special" />

      <div className={cx("wrapper")}>
        <List gap={0}>
          <div className={cx("body")}>
            <List gap={0} tight={false}>
              <div className={cx("frame-title")}>
                <List gap={0} align="left">
                  <div className={cx("text-title")}>건축자재 살펴보기</div>
                  <div className={cx("text-illust")}>
                    자재,하드웨어 등 원하는 건축자재를 쉽고 빠르게 찾아봐요.
                  </div>
                </List>
              </div>
            </List>
          </div>
          {response && (
            <NotionRenderer
              recordMap={response}
              fullPage
              components={{
                Code,
                Collection,
                Equation,
                Modal,
                Pdf,
                nextLink: NotionLink,
              }}
            />
          )}
        </List>
      </div>
    </>
  );
};

export default SpecialPage;
