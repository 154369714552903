export const filter_data = {
  sort: {
    name: "정렬하기",
    list: [
      { kor: "좋아요 순", eng: "like", id: "like" },
      { kor: "가나다 순", eng: "lexicographic", id: "lexicographic" },
      { kor: "최신순", eng: "new", id: "new" },
      { kor: "오래된 순", eng: "old", id: "old" },
    ],
  },
  show: {
    name: "보기 형식",
    list: [
      { kor: "카드 형태로 보기", eng: "card", id: "card" },
      { kor: "세부 정보 보기", eng: "detail", id: "detail" },
      { kor: "이미지만 보기", eng: "image", id: "image" },
    ],
  },
};

export const getSortQuery = (sort_id, collection) => {
  switch (sort_id) {
    case "like":
      if (collection == "brand_recommend") {
        return [
          {
            $lookup: {
              from: "brand",
              localField: "_id", // brand_recommend의 _id 필드
              foreignField: "_id", // brand의 _id 필드
              as: "brandData", // 결합된 데이터를 저장할 필드
            },
          },
          {
            $addFields: {
              sortField: {
                $ifNull: [{ $arrayElemAt: ["$brandData.like_num", 0] }, 0],
              }, // like_num이 없으면 0으로 설정
            },
          },
          { $sort: { sortField: -1 } },
          { $project: { sortField: 0, brandData: 0 } },
        ];
      }
      return [
        {
          $addFields: {
            sortField: { $ifNull: ["$like_num", 0] }, // like_num이 없으면 0으로 설정
          },
        },
        { $sort: { sortField: -1 } },
        { $project: { sortField: 0 } },
      ];
    case "lexicographic":
      return [{ $sort: { name: 1, _id: 1 } }];
    case "new":
      return [{ $sort: { update_timestamp: -1, _id: 1 } }];
    case "old":
      return [{ $sort: { update_timestamp: 1, _id: 1 } }];
    default:
      return [{ $sort: { like_num: -1, _id: 1 } }];
  }
};
