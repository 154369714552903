import React, { useEffect, useState, useRef, Suspense } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./MaterialBoard.module.scss";
import useGlobalVar from "../hooks/useGlobalVar";
import useGlobalData from "../hooks/useGlobalData";
import LoadedImage from "../atoms/LoadedImage";
import { motion, useDragControls } from "framer-motion";
import { ipad_width } from "../util/style";

const cx = classNames.bind(styles);

const MaterialBoard = ({ src, initial, zIndex, bound_ref }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const show_type_list = [
    { kor: "제품 이미지", eng: "image" },
    { kor: "도면", eng: "2D" },
    { kor: "텍스쳐", eng: "Tx" },
    { kor: "3D 모델", eng: "3D" },
  ];
  const ref = useRef();
  const [show_type, setShowType] = useState("image");
  const [tap, setTap] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);
  const controls = useDragControls();
  const [resize_type, setResizeType] = useState(false);

  useEffect(() => {
    if (initial) {
      setPos({ x: initial.x ?? 0, y: initial.y ?? 0 });
      setWidth(initial.width ?? 200);
      setHeight(initial.height ?? 200);
    }
  }, [initial]);

  const handlePanStart = () => {
    setPos((pos) => ({
      x: Number(
        ref.current?.style?.transform
          ?.split("translateX(")?.[1]
          ?.split("px")?.[0] ?? pos.x
      ),
      y: Number(
        ref.current?.style?.transform
          ?.split("translateY(")?.[1]
          ?.split("px")?.[0] ?? pos.y
      ),
    }));
  };

  const handlePan = (_, info) => {
    const min_length = 48;
    if (resize_type) {
      switch (resize_type) {
        case "w":
          setPos((pos) => ({
            x:
              pos.x +
              (width - info.delta.x > min_length ? info.delta.x / 2 : 0),
            y: pos.y,
          }));
          setWidth((w) =>
            width - info.delta.x > min_length ? w - info.delta.x : min_length
          );
          break;
        case "e":
          setPos((pos) => ({
            x:
              pos.x +
              (width + info.delta.x > min_length ? info.delta.x / 2 : 0),
            y: pos.y,
          }));
          setWidth((w) =>
            width + info.delta.x > min_length ? w + info.delta.x : min_length
          );
          break;
        case "n":
          setPos((pos) => ({
            x: pos.x,
            y:
              pos.y +
              (height - info.delta.y > min_length ? info.delta.y / 2 : 0),
          }));
          setHeight((h) =>
            height - info.delta.y > min_length ? h - info.delta.y : min_length
          );
          break;
        case "s":
          setPos((pos) => ({
            x: pos.x,
            y:
              pos.y +
              (height + info.delta.y > min_length ? info.delta.y / 2 : 0),
          }));
          setHeight((h) =>
            height + info.delta.y > min_length ? h + info.delta.y : min_length
          );
          break;
        case "sw":
          setPos((pos) => ({
            x:
              pos.x +
              (width - info.delta.x > min_length ? info.delta.x / 2 : 0),
            y:
              pos.y +
              (height + info.delta.y > min_length ? info.delta.y / 2 : 0),
          }));
          setWidth((w) =>
            width - info.delta.x > min_length ? w - info.delta.x : min_length
          );
          setHeight((h) =>
            height + info.delta.y > min_length ? h + info.delta.y : min_length
          );
          break;
        case "se":
          setPos((pos) => ({
            x:
              pos.x +
              (width + info.delta.x > min_length ? info.delta.x / 2 : 0),
            y:
              pos.y +
              (height + info.delta.y > min_length ? info.delta.y / 2 : 0),
          }));
          setWidth((w) =>
            width + info.delta.x > min_length ? w + info.delta.x : min_length
          );
          setHeight((h) =>
            height + info.delta.y > min_length ? h + info.delta.y : min_length
          );
          break;
        case "nw":
          setPos((pos) => ({
            x:
              pos.x +
              (width - info.delta.x > min_length ? info.delta.x / 2 : 0),
            y:
              pos.y +
              (height - info.delta.y > min_length ? info.delta.y / 2 : 0),
          }));
          setWidth((w) =>
            width - info.delta.x > min_length ? w - info.delta.x : min_length
          );
          setHeight((h) =>
            height - info.delta.y > min_length ? h - info.delta.y : min_length
          );
          break;
        case "ne":
          setPos((pos) => ({
            x:
              pos.x +
              (width + info.delta.x > min_length ? info.delta.x / 2 : 0),
            y:
              pos.y +
              (height - info.delta.y > min_length ? info.delta.y / 2 : 0),
          }));
          setWidth((w) =>
            width + info.delta.x > min_length ? w + info.delta.x : min_length
          );
          setHeight((h) =>
            height - info.delta.y > min_length ? h - info.delta.y : min_length
          );
          break;
        default:
          break;
      }
    }
  };

  const handlePanEnd = () => {
    const min_length = 64;
    setWidth((w) => Math.max(w, min_length));
    setHeight((h) => Math.max(h, min_length));
    setTap(false);
  };

  return (
    <motion.div
      className={cx("frame-board-1")}
      ref={ref}
      animate={{
        ...pos,
        width,
        height,
        scale: tap ? 0.95 : 1,
        zIndex: zIndex ?? 1,
      }}
      transition={{
        type: "spring",
        stiffness: 75,
        duration: tap || resize_type ? 0 : 0.15,
        scale: { type: "linear", duration: 0.15 },
      }}
      drag
      dragListener={false}
      onDragEnd={() => setTap(false)}
      dragControls={controls}
      dragMomentum={false}
      dragConstraints={
        tap
          ? bound_ref ?? {
              top: -10000,
              left: -10000,
              right: 10000,
              bottom: 10000,
            }
          : {
              top: -10000,
              left: -10000,
              right: 10000,
              bottom: 10000,
            }
      }
      onPanStart={handlePanStart}
      onPan={handlePan}
      onPanEnd={handlePanEnd}
    >
      <Suspense>
        <LoadedImage
          className={cx("image")}
          src={src}
          delay={0.3}
          duration={0.3}
          width="100%"
          height="100%"
        />
      </Suspense>
      {!global_var.media_mobile &&
        ["e", "s", "w", "n", "se", "sw", "ne", "nw"].map((e, idx) => (
          <motion.div
            key={idx}
            className={cx("frame-modifying-box", e)}
            onTapStart={() => setResizeType(e)}
            onTap={() => setResizeType(false)}
            onTapCancel={() => setResizeType(false)}
          />
        ))}
      {!global_var.media_mobile && (
        <motion.div
          className={cx("frame-modifying-box", "c")}
          onTapStart={(event) => {
            setTap(true);
            controls.start(event);
          }}
          // onPointerDown={(event) => {
          //   controls.start(event);
          // }}
          onTap={() => setTap(false)}
          onTapCancel={() => setTap(false)}
        />
      )}
    </motion.div>
  );
};

export default MaterialBoard;
