import React, {
  useEffect,
  useReducer,
  useState,
  Suspense,
  useLayoutEffect,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./BrandPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";

import TableCard from "../component/TableCard";
import Button from "../atoms/Button";

import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import {
  convertBrandRecommendDataForBrandPage,
  getBrandPageQueryOfBrandRecommendData,
} from "../functions/handleBrandRecommendData";
import { API_URI } from "../shortcut";
import {
  convertBrandDataForBrandPage,
  getBrandPageQueryOfBrandData,
} from "../functions/handleBrandData";
import Popup from "../component/Popup";
import { MATERIAL_TYPE } from "../functions/types";
import CustomLink from "../component/CustomLink";
import useQueryParam from "../hooks/useQueryParam";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const sample_like_data = ["바스데이", "대림바스"];

const data_existing_brand_id_list = ["윤현상재_0", "티엠티티_0", "FILOBE_0"];

const BrandPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [brand_recommend_data, setBrandRecommendData] = useState([]);
  const [brand_data, setBrandData] = useState([]);

  const query = useQueryParam();

  useLayoutEffect(() => {
    axios
      .put(
        API_URI + "data",
        getBrandPageQueryOfBrandRecommendData(
          global_data.filter?.current,
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) =>
        setBrandRecommendData(convertBrandRecommendDataForBrandPage(res.data))
      )
      .catch(console.log);
  }, [global_data.filter, global_var.data_sort, global_data.search_data]);

  useLayoutEffect(() => {
    if (brand_recommend_data.length > 0) {
      axios
        .put(
          API_URI + "data",
          getBrandPageQueryOfBrandData(
            brand_recommend_data.map((data) => data.brand_id)
          )
        )
        .then((res) => setBrandData(convertBrandDataForBrandPage(res.data)))
        .catch(console.log);
    }
  }, [brand_recommend_data]);

  return (
    <>
      <MetaData path="/brand" />
      <Header emph="brand" />
      <div className={cx("wrapper")}>
        <List gap={0} tight={false}>
          <div className={cx("body")}>
            <List gap={0} tight={false}>
              <List gap={0} tight={false}>
                <div className={cx("frame-title")}>
                  <List gap={0} align="left">
                    <div className={cx("text-title")}>브랜드 모아보기</div>
                    <div className={cx("text-illust")}>
                      제품별로 자주 쓰이는 브랜드를 알아보아요.
                    </div>
                  </List>
                </div>
                <Filter
                  show_properties={["type"]}
                  show_dropdown={["sort"]}
                  onSearch={(e) => {
                    window.history.replaceState({}, "", "/brand?search=" + e);
                  }}
                />
                {/* <div className={cx("frame-content")}> */}
                <ScrollContainer
                  horizontal
                  vertical={false}
                  className={cx("frame-content")}
                  hideScrollbars={false}
                >
                  <List
                    type="column"
                    align="left"
                    attach="left"
                    gap={1}
                    tight={false}
                    fillY
                    multiple_line
                    style={{ alignContent: "flex-start" }}
                  >
                    <div className={cx("frame-margin")}></div>
                    {((global_data.filter?.current?.type?.length ?? 0) == 0
                      ? MATERIAL_TYPE
                      : global_data.filter.current.type
                    ).map((mat_type, mat_idx) => (
                      <motion.div
                        key={mat_type + mat_idx + global_data.filter?.emph_now}
                        className={cx("frame-div")}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 1 }}
                        transition={{
                          delay: mat_idx * 0.1,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <List
                          gap={0.5}
                          tight={false}
                          style={{ height: "auto" }}
                        >
                          <div className={cx("text-section")}>{mat_type}</div>
                          <div className={cx("frame-scroll")}>
                            <List gap={0.25} tight={false}>
                              <Suspense>
                                {brand_recommend_data.filter(
                                  (data) =>
                                    data.material_type.includes(mat_type) &&
                                    brand_data[data.brand_id] != undefined
                                ).length == 0 ? (
                                  <motion.div
                                    className={cx("text-error")}
                                    initial={{ opacity: 0.3 }}
                                    animate={{ opacity: 0.7 }}
                                    transition={{
                                      repeat: Infinity,
                                      repeatType: "reverse",
                                      ease: "easeInOut",
                                      duration: 1.5,
                                    }}
                                  >
                                    해당 유형은 아직 추천 브랜드를 찾지
                                    못했어요.
                                  </motion.div>
                                ) : (
                                  brand_recommend_data
                                    .filter(
                                      (data) =>
                                        data.material_type.includes(mat_type) &&
                                        brand_data[data.brand_id] != undefined
                                    )
                                    .map((data, idx) => (
                                      <CustomLink
                                        key={idx}
                                        to={{
                                          pathname: "/brand/" + data.brand_id,
                                          state: { from: "brand" },
                                        }}
                                        style={{ width: "100%" }}
                                      >
                                        <TableCard
                                          key={data.brand_id}
                                          id={data.brand_id}
                                          collection="brand"
                                          title={
                                            data.name +
                                            (data.brand_type == "유통사"
                                              ? "[유통사]"
                                              : "")
                                          }
                                          illust={[
                                            brand_data[data.brand_id]
                                              ?.site_title,
                                            brand_data[data.brand_id]?.illust,
                                          ].join(" - ")}
                                          illust2={data.specialty}
                                          like={sample_like_data.includes(
                                            data.brand_id
                                          )}
                                          like_num={
                                            brand_data[data.brand_id]?.like_num
                                          }
                                          src={brand_data[data.brand_id]?.src}
                                        ></TableCard>
                                      </CustomLink>
                                    ))
                                )}
                              </Suspense>
                            </List>
                          </div>
                        </List>
                      </motion.div>
                    ))}
                    <div className={cx("frame-margin")}></div>
                  </List>
                </ScrollContainer>
                {/* </div> */}
              </List>
            </List>
          </div>
        </List>
      </div>
    </>
  );
};

export default BrandPage;
