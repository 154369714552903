import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ path }) => {
  const meta_data = useMemo(() => {
    switch (path) {
      case "/":
        return {
          title: "머티리얼플랫폼",
          description:
            "마감재, 하드웨어 등 원하는 건축자재를 쉽고 빠르게 찾아봐요!",
        };
      case "/material":
        return {
          title: "건축자재 살펴보기",
          description:
            "자재, 하드웨어 등 원하는 건축자재를 쉽고 빠르게 찾아봐요.",
        };
      case "/brand":
        return {
          title: "브랜드 모아보기",
          description: "제품별로 자주 쓰이는 브랜드를 알아보아요.",
        };
      case "/map":
        return {
          title: "지도로 사례보기",
          description:
            "내가 원하는 자재가 실제로 어디에 적용되었는지 지도에서 둘러보아요.",
        };
      case "/materialboard":
        return {
          title: "머티리얼보드",
          description: "조합하고 비교해봐요.",
        };
      case "/materialboard/board":
        return {
          title: "보드 만들기",
          description: "조합하고 비교해봐요.",
        };
      case "/materialboard/like":
        return {
          title: "내 좋아요 목록",
          description: "조합하고 비교해봐요.",
        };
      case "/RAQ":
        return {
          title: "견적 일괄 요청하기",
          description: "한번에 견적을 알아보세요.",
        };
      case "/survey":
        return {
          title: "[설문조사] 머티리얼플랫폼",
          description: "매월 10분을 추첨하여 스타벅스 상품권을 드립니다!",
        };
    }
  }, [path]);

  return (
    <Helmet>
      <title>{meta_data?.title}</title>
      <meta name="title" content={meta_data?.title} />
      <meta name="description" content={meta_data?.description} />
      <meta property="og:title" content={meta_data?.title} />
      <meta property="og:description" content={meta_data?.description} />
      <meta name="twitter:title" content={meta_data?.title} />
      <meta name="twitter:description" content={meta_data?.description} />
    </Helmet>
  );
};

export default MetaData;
