import React, { useEffect, useReducer, useState, useRef } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./FilterPopupScienario.module.scss";
import List from "../atoms/List";
import useGlobalData from "../hooks/useGlobalData";
import { _deepcopy, _transformScroll } from "../util/alias";
import Chip from "../atoms/Chip";
import useGlobalVar from "../hooks/useGlobalVar";
import DropDown from "../atoms/DropDown";
import Search from "../atoms/Search";
import { filter_data } from "../functions/filterData";
import Popup from "./Popup";
import Button from "../atoms/Button";
import { MATERIAL_TYPE, LOCATION_TYPE, SPEC_TYPE } from "../functions/types";

const cx = classNames.bind(styles);

const getTypeByProp = (prop) => {
  switch (prop) {
    case "location":
      return LOCATION_TYPE;
    case "spec":
      return SPEC_TYPE;
    case "type":
    default:
      return MATERIAL_TYPE;
  }
};

const CreateOrUpdateGroupFilterPopup = ({
  type = "update",
  show_properties = ["type"],
  popup_data,
  setPopupData = () => {},
}) => {
  const initial_group_data = {
    title: "",
    location: [],
    spec: [],
    type: [],
  };
  const [global_data, setGlobalData] = useGlobalData();
  const [new_group_data, setNewGroupData] = useState(initial_group_data);

  useEffect(() => {
    if (popup_data?.group_data)
      setNewGroupData(_deepcopy(popup_data?.group_data));
  }, [popup_data]);

  const properties_name = {
    location: { kor: "위치", eng: "location" },
    spec: { kor: "성능", eng: "spec" },
    type: { kor: "종류", eng: "type" },
  };

  const title_ref = useRef();

  return (
    <div className={cx("frame-popup")}>
      <List align="center" gap={1} fillX>
        <div className={cx("text-popup-title")}>
          {type === "update"
            ? '"' + new_group_data?.title + '" 그룹 수정하기'
            : (new_group_data?.title
                ? '"' + new_group_data?.title + '" '
                : "") + "그룹 추가하기"}
        </div>
        <List align="center" gap={0.5} fillX>
          <div className={cx("text-popup", "left")}>그룹 이름</div>
          <textarea
            className={cx("frame-popup-textarea")}
            rows="1"
            placeholder="제목을 입력해주세요."
            ref={title_ref}
            defaultValue={new_group_data?.title ?? ""}
            onBlur={() => {
              setNewGroupData((data) => ({
                ...data,
                title: title_ref.current.value,
              }));
            }}
          ></textarea>
        </List>
        <div className={cx("text-popup", "left")}>그룹 안에서 볼 자재 종류</div>
        <List type="row" attach="left" gap={0.375} multiple_line fillX>
          {show_properties.map((prop, idx) => (
            <>
              <Chip
                clickable
                key={idx}
                clicked={
                  new_group_data?.[prop]?.length == getTypeByProp(prop).length
                }
                onClick={() => {
                  if (
                    new_group_data?.[prop]?.length == getTypeByProp(prop).length
                  ) {
                    setNewGroupData((new_group_data) => {
                      const temp = { ...new_group_data };
                      temp[prop] = [];
                      return temp;
                    });
                  } else {
                    setNewGroupData((new_group_data) => {
                      const temp = { ...new_group_data };
                      temp[prop] = getTypeByProp(prop);
                      return temp;
                    });
                  }
                }}
              >
                모든 {properties_name[prop].kor}
              </Chip>
              {getTypeByProp(prop).map((e) => (
                <Chip
                  clickable
                  clicked={new_group_data?.[prop]?.includes(e)}
                  onClick={() => {
                    if (new_group_data?.[prop]?.includes(e)) {
                      setNewGroupData((new_group_data) => {
                        const temp = { ...new_group_data };
                        temp[prop] = temp[prop].filter((e2) => e2 !== e);
                        console.log(temp);
                        return temp;
                      });
                    } else {
                      setNewGroupData((new_group_data) => {
                        const temp = { ...new_group_data };
                        temp[prop] = temp[prop] ?? [];
                        temp[prop].push(e);
                        return temp;
                      });
                    }
                  }}
                >
                  {e}
                </Chip>
              ))}
            </>
          ))}
        </List>
        <List attach="space" type="row" fillX gap={0.5} multiple_line>
          <List attach="center" type="row" gap={0.5} multiple_line>
            <Button
              type="green-light"
              onClick={() => {
                setPopupData(null);
              }}
            >
              뒤로가기
            </Button>
            {type == "update" && (
              <Button
                type="green-light"
                onClick={() => {
                  setPopupData({
                    id: "wanna_delete_alert",
                    group_data: new_group_data,
                  });
                }}
              >
                그룹 삭제하기
              </Button>
            )}
          </List>
          <Button
            type="brown-light"
            onClick={() => {
              if (
                (new_group_data.title?.replaceAll(" ", "") ?? "").length == 0
              ) {
                setPopupData({ id: "no_title_alert" });
                setTimeout(() => {
                  setPopupData({ id: type, group_data: new_group_data });
                }, 1200);
              } else if (type == "update") {
                const temp_filter = _deepcopy(global_data.filter);
                temp_filter.group_list[temp_filter.emph_now] = new_group_data;
                temp_filter.emph_list[temp_filter.emph_now] =
                  _deepcopy(new_group_data);
                temp_filter.current = _deepcopy(new_group_data);
                setGlobalData({ filter: temp_filter });
                setPopupData(null);
              } else {
                const temp_filter = _deepcopy(global_data.filter);
                temp_filter.group_list.push(new_group_data);
                temp_filter.emph_list.push(_deepcopy(new_group_data));
                temp_filter.current = _deepcopy(new_group_data);
                temp_filter.emph_now = temp_filter.group_list?.length - 1;
                setGlobalData({ filter: temp_filter });
                setPopupData(null);
              }
            }}
          >
            저장하기
          </Button>
        </List>
      </List>
    </div>
  );
};

const FilterPopupScienario = ({
  type,
  show_properties = ["type"],
  popup_data,
  setPopupData = () => {},
}) => {
  const [global_data, setGlobalData] = useGlobalData();

  const whole_group_data = {
    title: "전체",
    location: [],
    spec: [],
    type: [],
  };

  return (
    <Popup
      change_trigger={popup_data}
      use_backdrop
      onClose={() => {
        setPopupData();
      }}
    >
      {popup_data &&
        (["create", "update"].includes(type) ? (
          <CreateOrUpdateGroupFilterPopup
            type={type}
            popup_data={popup_data}
            setPopupData={setPopupData}
            show_properties={show_properties}
          />
        ) : type == "no_title_alert" ? (
          <div className={cx("frame-popup")}>
            <div className={cx("text-popup")}>그룹 이름을 정해주세요!</div>
          </div>
        ) : type == "wanna_delete_alert" ? (
          <div className={cx("frame-popup")}>
            <List align="center" gap={1} fillX>
              <div className={cx("text-popup")}>
                정말 삭제하시겠어요? 삭제하시면 다시 복구할 수 없어요.
              </div>
              <List attach="center" type="row" fillX gap={0.5} multiple_line>
                <Button
                  type="green-light"
                  onClick={() => {
                    popup_data.no_backward
                      ? setPopupData(null)
                      : setPopupData({ ...popup_data, id: "update" });
                  }}
                >
                  뒤로가기
                </Button>
                <Button
                  type="brown-light"
                  onClick={() => {
                    const emph_idx = global_data.filter.emph_now;
                    const new_group_list = global_data.filter.group_list.filter(
                      (_, idx) => idx != emph_idx
                    );
                    const new_emph_list = global_data.filter.emph_list.filter(
                      (_, idx) => idx != emph_idx
                    );
                    setGlobalData({
                      filter: {
                        ...global_data.filter,
                        group_list: new_group_list,
                        emph_list: new_emph_list,
                        emph_now: -1,
                        current: whole_group_data,
                      },
                    });
                    setPopupData({ ...popup_data, id: "deleted_alert" });
                    setTimeout(() => {
                      setPopupData(null);
                    }, 1500);
                  }}
                >
                  그룹 삭제하기
                </Button>
              </List>
            </List>
          </div>
        ) : type == "deleted_alert" ? (
          <div className={cx("frame-popup")}>
            <div className={cx("text-popup")}>
              {popup_data?.group_data?.title
                ? '"' + popup_data?.group_data?.title + '" '
                : ""}
              그룹이 삭제되었어요.
            </div>
          </div>
        ) : undefined)}
    </Popup>
  );
};

export default FilterPopupScienario;
