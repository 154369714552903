import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./Popup.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Popup = ({
  change_trigger,
  children,
  onClose = () => {
    console.log("clicked background of popup");
  },
  use_backdrop = false,
  duration = 0.3,
}) => {
  const [displayedChildren, setDisplayedChildren] = useState(children);
  const [isVisible, setIsVisible] = useState(!!children);
  const [wasNull, setWasNull] = useState(!children);

  const trigger = change_trigger ?? children;

  useEffect(() => {
    if (children) {
      if (wasNull) {
        // If trigger were null previously, display immediately without delay
        setDisplayedChildren(children);
        setIsVisible(true);
        setWasNull(false);
      } else if (children !== displayedChildren) {
        // If trigger have changed, animate the transition
        setIsVisible(false);
        setTimeout(() => {
          setDisplayedChildren(children);
          setIsVisible(true);
        }, duration * 1000);
      }
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setDisplayedChildren(null);
        setWasNull(true);
      }, duration * 1000);
    }
  }, [trigger, displayedChildren, wasNull]);

  const variants = {
    hidden: { opacity: 0, scale: 0.8, x: "-50%", y: "-50%" },
    visible: { opacity: 1, scale: 1, x: "-50%", y: "-50%" },
    exit: { opacity: 0, scale: 0.8, x: "-50%", y: "-50%" },
  };

  const handleBackgroundClick = () => {
    if (use_backdrop) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {displayedChildren && (
        <>
          {use_backdrop && (
            <motion.div
              key="overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: isVisible ? 0.5 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: duration }}
              className={cx("overlay", isVisible ? "" : "hidden")}
              onClick={handleBackgroundClick}
            />
          )}
          <motion.div
            key="popup"
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            exit="exit"
            variants={variants}
            transition={{ duration: duration, type: "spring" }}
            className={cx("popup")}
          >
            {displayedChildren}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Popup;
