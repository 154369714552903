import React from "react";
import styles from "./SurveyPage.module.scss";
import classNames from "classnames/bind";

import { SERVICE_URI_AFTER_SURVEY } from "../shortcut";
import Button from "../atoms/Button";
import { ReactComponent as IconRight } from "../svgs/IconRight.svg";
import List from "../atoms/List";
import { motion } from "framer-motion";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const SurveyPage = () => {
  return (
    <>
      <MetaData path="/survey" />
      <div className={cx("wrapper")}>
        <iframe
          className={cx("iframe")}
          src="https://smore.im/form/9xtO4yqwOY"
        />
        <div className={cx("overlay-wrapper")}>
          <motion.div
            initial={{ y: -3, opacity: 0.8 }}
            animate={{ y: 2, opacity: 1 }}
            transition={{
              repeat: Infinity,
              duration: 0.8,
              ease: "easeIn",
              repeatType: "reverse",
            }}
            className={cx("overlay")}
          >
            <List align="right" tight={false}>
              <Button
                color="green-light"
                onClick={() => {
                  window.open(SERVICE_URI_AFTER_SURVEY, "materialplatform");
                }}
                style={{ boxShadow: "0 0 2rem rgba(0, 0, 0, 0.35)" }}
              >
                <List type="row" align="center">
                  사이트 방문하기
                  <p className={cx("frame-icon")}>
                    <IconRight className={cx("icon")} />
                  </p>
                </List>
              </Button>
            </List>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default SurveyPage;
