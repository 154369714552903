import React, { useEffect, useReducer, useState } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./Filter.module.scss";
import List from "../atoms/List";
import useGlobalData from "../hooks/useGlobalData";
import { _deepcopy, _transformScroll } from "../util/alias";
import Chip from "../atoms/Chip";
import useGlobalVar from "../hooks/useGlobalVar";
import DropDown from "../atoms/DropDown";
import Search from "../atoms/Search";
import { filter_data } from "../functions/filterData";
import Popup from "./Popup";
import Button from "../atoms/Button";
import FilterPopupScienario from "./FilterPopupScienario";
import { ipad_width } from "../util/style";

const cx = classNames.bind(styles);

const sample_data = {
  group_list: [
    {
      title: "오프닝 그룹",
      location: [],
      spec: [],
      type: ["창호", "문", "차양"],
    },
    {
      title: "외벽 그룹",
      location: [],
      spec: [],
      type: [
        "벽돌",
        "타일",
        "석재",
        "미장",
        "페인트",
        "차양",
        "큐비클",
        "금속",
      ],
    },
    {
      title: "수직 동선 그룹",
      location: [],
      spec: [],
      type: ["엘리베이터", "에스컬레이터", "난간", "특수제품"],
    },
  ],
  emph_list: [
    {
      title: "오프닝 그룹",
      location: [],
      spec: [],
      type: ["창호"],
    },
    {
      title: "실내 그룹",
      location: [],
      spec: [],
      type: ["벽돌", "타일"],
    },
    {
      title: "수직 동선 그룹",
      location: [],
      spec: [],
      type: ["엘리베이터", "에스컬레이터", "난간", "특수제품"],
    },
  ],
  emph_now: 0,
};

const search_id = "filter";

const Filter = ({
  show_properties = ["type"],
  show_dropdown = ["show", "sort"],
  no_filter = false,
  onSearch = () => {},
}) => {
  const [global_data, setGlobalData] = useGlobalData();
  const [global_var, setGlobalVar] = useGlobalVar();
  const [popup_data, setPopupData] = useState(null);

  //////////////////////////
  useEffect(() => {
    if (global_data.filter === null && global_data.data_loaded) {
      setGlobalData({ filter: sample_data });
    }
  }, [global_data.filter, global_data.data_loaded]);
  //////////////////////////

  const properties_name = {
    location: { kor: "위치", eng: "location" },
    spec: { kor: "성능", eng: "spec" },
    type: { kor: "종류", eng: "type" },
  };

  return (
    <div className={cx("wrapper")}>
      <List
        type={global_var.window_width < ipad_width ? "column" : "row"}
        align="left"
        gap={1}
      >
        <div className={cx("frame-div")}>
          {no_filter ? null : (
            <List align="left" gap={0.375}>
              <List type="row" gap={0.375} multiple_line>
                <Chip
                  clickable
                  clicked={global_data.filter?.emph_now == -1}
                  onClick={() => {
                    setGlobalData({
                      filter: {
                        ...global_data.filter,
                        current: {
                          location: [],
                          spec: [],
                          title: "전체",
                          type: [],
                        },
                        emph_now: -1,
                      },
                    });
                  }}
                  GTM="Filter_전체"
                >
                  전체
                </Chip>
                {global_data.filter?.group_list.map((group, idx) => (
                  <Chip
                    key={group.title + idx}
                    clickable
                    clicked={global_data.filter?.emph_now == idx}
                    onClick={() => {
                      setGlobalData({
                        filter: {
                          ...global_data.filter,
                          current: global_data.filter.emph_list[idx],
                          emph_now: idx,
                        },
                      });
                    }}
                    GTM="Filter_그룹"
                  >
                    {group.title}
                  </Chip>
                ))}
                <Chip
                  clickable
                  onClick={() => {
                    setPopupData({
                      id: "create",
                    });
                  }}
                  GTM="Filter_그룹_추가하기"
                >
                  + 그룹 추가하기
                </Chip>
              </List>
              {global_data.filter?.emph_now > -1 ? (
                <div className={cx("frame-subfilter")}>
                  <List align="left">
                    <List type="row" gap={0.375} multiple_line>
                      {show_properties.map((prop, idx) => (
                        <React.Fragment key={idx}>
                          {idx !== 0 ? (
                            <div className={cx("text-subsection")} key={idx}>
                              and
                            </div>
                          ) : null}
                          <Chip
                            clickable
                            key={idx}
                            clicked={
                              global_data.filter?.group_list[
                                global_data.filter?.emph_now
                              ][prop].length ==
                              global_data.filter?.emph_list[
                                global_data.filter?.emph_now
                              ][prop].length
                            }
                            onClick={() => {
                              const new_filter = { ...global_data.filter };
                              if (
                                global_data.filter?.group_list[
                                  global_data.filter?.emph_now
                                ][prop].length ==
                                global_data.filter?.emph_list[
                                  global_data.filter?.emph_now
                                ][prop].length
                              ) {
                                new_filter.emph_list[
                                  global_data.filter.emph_now
                                ][prop] = [];
                              } else {
                                new_filter.emph_list[
                                  global_data.filter.emph_now
                                ][prop] = [
                                  ...new_filter.group_list[
                                    global_data.filter.emph_now
                                  ][prop],
                                ];
                              }
                              setGlobalData({
                                filter: new_filter,
                              });
                            }}
                            GTM="Filter_타입"
                          >
                            모든 {properties_name[prop].kor}
                          </Chip>
                          {global_data.filter?.group_list[
                            global_data.filter?.emph_now
                          ][prop].map((e, e_idx) => (
                            <Chip
                              key={e + e_idx}
                              clickable
                              clicked={global_data.filter?.emph_list[
                                global_data.filter?.emph_now
                              ][prop].includes(e)}
                              onClick={() => {
                                const new_filter = { ...global_data.filter };
                                const now_idx =
                                  global_data.filter?.emph_list[
                                    global_data.filter?.emph_now
                                  ][prop].indexOf(e);
                                if (now_idx > -1) {
                                  new_filter.emph_list[
                                    global_data.filter.emph_now
                                  ][prop].splice(now_idx, 1);
                                } else {
                                  new_filter.emph_list[
                                    global_data.filter.emph_now
                                  ][prop].push(e);
                                }
                                new_filter.current =
                                  new_filter.emph_list[
                                    global_data.filter.emph_now
                                  ];
                                setGlobalData({
                                  filter: new_filter,
                                });
                              }}
                              GTM="Filter_타입"
                            >
                              {e}
                            </Chip>
                          ))}
                          <Chip
                            clickable
                            onClick={() => {
                              setPopupData({
                                id: "update",
                                group_data:
                                  global_data.filter?.group_list[
                                    global_data.filter?.emph_now
                                  ],
                              });
                            }}
                            GTM="Filter_타입_추가하기"
                          >
                            +
                          </Chip>
                        </React.Fragment>
                      ))}
                      <Chip
                        clickable
                        onClick={() => {
                          setPopupData({
                            id: "wanna_delete_alert",
                            no_backward: true,
                            group_data:
                              global_data.filter?.group_list[
                                global_data.filter?.emph_now
                              ],
                          });
                        }}
                        GTM="Filter_그룹_삭제하기"
                      >
                        그룹 삭제하기
                      </Chip>
                    </List>
                  </List>
                </div>
              ) : (
                <></>
              )}
            </List>
          )}
        </div>
        <div className={cx("frame-div")}>
          <List type="row-reverse" attach="start" multiple_line>
            <Search
              id={search_id}
              placeholder="검색하기"
              onClick={onSearch}
            ></Search>
            <List type="row-reverse" attach="start" multiple_line gap={0.25}>
              {show_dropdown.includes("sort") ? (
                <DropDown
                  {...filter_data.sort}
                  emph_idx={global_var.filter_data_sort}
                  onClick={(e) => {
                    setGlobalVar({
                      filter_data_sort: e,
                      data_sort: filter_data.sort.list[e == -1 ? 0 : e].id,
                    });
                  }}
                />
              ) : (
                <></>
              )}
              {show_dropdown.includes("show") ? (
                <DropDown
                  {...filter_data.show}
                  emph_idx={global_var.filter_data_show}
                  onClick={(e) => {
                    setGlobalVar({
                      filter_data_show: e,
                      data_show: filter_data.show.list[e == -1 ? 0 : e].id,
                    });
                  }}
                />
              ) : (
                <></>
              )}
            </List>
          </List>
        </div>
      </List>
      <FilterPopupScienario
        type={popup_data?.id}
        show_properties={show_properties}
        popup_data={popup_data}
        setPopupData={setPopupData}
      />
    </div>
  );
};

export default Filter;
