import React, { useEffect, useReducer, useState } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./NavCardList.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";
import { _transformScroll } from "../util/alias";
import NavCard from "../component/NavCard";
import { ipad_width } from "../util/style";
import ScrollContainer from "react-indiana-drag-scroll";

const cx = classNames.bind(styles);
// var mapDiv = document.getElementById('map');
// var map = new naver.maps.Map(mapDiv);

const Wrapper = ({ children }) => {
  const [global_var, setGlobalVar] = useGlobalVar();

  return global_var.window_width < ipad_width ? (
    <ScrollContainer
      horizontal
      vertical={false}
      className={cx("frame-content")}
    >
      {children}
    </ScrollContainer>
  ) : (
    children
  );
};

const NavCardList = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  return (
    <Wrapper>
      <List
        type="row"
        align="left"
        attach={global_var.window_width < ipad_width ? "left" : "center"}
        gap={1}
        // multiple_line
        // tight={false}
        // fillY
      >
        <div className={cx("frame-qdiv")}>
          <NavCard
            src="/img/icon_material.png"
            link_to="/material"
            title="건축자재 살펴보기"
            illust="마감재, 하드웨어 등 원하는 건축자재를 쉽고 빠르게 찾아봐요."
          ></NavCard>
        </div>
        <div className={cx("frame-qdiv")}>
          <NavCard
            src="/img/icon_brand.png"
            link_to="/brand"
            title="브랜드 모아보기"
            illust="제품별로 자주 쓰이는 브랜드를 알아보아요."
          ></NavCard>
        </div>
        <div className={cx("frame-qdiv")}>
          <NavCard
            src="/img/icon_map.png"
            link_to="/map"
            title="지도로 사례보기"
            illust="내가 원하는 자재가 어디에 적용되었는지 지도에서 둘러보아요."
          ></NavCard>
        </div>
        <div className={cx("frame-qdiv")}>
          <List align="center" gap={1}>
            <div className={cx("frame-qqdiv")}>
              <NavCard
                src="/img/icon_board.png"
                link_to="/materialboard"
                title="머티리얼보드"
                illust="조합하고 비교해봐요."
                small
              ></NavCard>
            </div>
            <div className={cx("frame-qqdiv")}>
              <NavCard
                src="/img/icon_RAQ.png"
                link_to="/RAQ"
                title="견적요청"
                illust="한번에 견적을 알아봐요."
                small
              ></NavCard>
            </div>
          </List>
        </div>
      </List>
    </Wrapper>
  );
};

export default NavCardList;
