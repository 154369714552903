import { getSortQuery } from "./filterData";
import { S3_URI } from "../shortcut";

export const mapUse = (use) => {
  switch (use) {
    case "단독":
      return "단독주택";
    case "공동":
      return "공동주택";
    case "근생":
      return "근린생활시설";
    case "문화":
    case "문화집회":
      return "문화 및 집회시설";
    case "교육":
    case "교육연구":
      return "교육연구시설";
    case "숙박":
      return "숙박시설";
  }
};

export const getMapPageQueryOfArchitectureData = (
  { swLat, swLng, neLat, neLng },
  material_id_list,
  sort_type
) => {
  return {
    collection: "architecture",
    query: [
      {
        $match: {
          location: {
            $geoWithin: {
              $box: [
                [swLng, swLat], // 좌하단 좌표 [경도, 위도]
                [neLng, neLat], // 우상단 좌표 [경도, 위도]
              ],
            },
          },
        },
      },
      ...(material_id_list.length > 0
        ? [{ $match: { "used_materials.id": { $in: material_id_list } } }]
        : []),
      ...getSortQuery(sort_type),
      { $limit: 20 },
    ],
  };
};

export const getArchitectureIllustPageQueryOfArchitectureData = (id) => ({
  collection: "architecture",
  query: [{ $match: { architecture_id: id } }],
});

export const getMaterialIllustPageQueryOfArchitectureData = (
  id,
  sort_type
) => ({
  collection: "architecture",
  query: [
    { $match: { used_materials: { $elemMatch: { id: id } } } },
    ...getSortQuery(sort_type),
    { $limit: 100 },
  ],
});

export const getMaterialBoardPageQueryOfArchitectureData = (
  id_list,
  sort_type
) => ({
  collection: "architecture",
  query: [
    { $match: { architecture_id: { $in: id_list } } },
    ...getSortQuery(sort_type),
    { $limit: 100 },
  ],
});

export const convertArchitectureDataForMapPage = (data) => {
  return data.map((e) => ({
    ...e,
    title:
      e.bldg_name !== ""
        ? e.bldg_name
        : e.name_alt[0] ??
          (e.address.is_exact ? e.address.road : e.address.normal) +
            " " +
            e.use[0],
    illust: e.architect.join(" · "),
    illust2: e.address.is_exact ? e.address.road : e.address.normal,
    src: S3_URI + e.image,
    lat: e.location.coordinates[1],
    lng: e.location.coordinates[0],
    rand: e.location.randoms,
  }));
};

export const convertArchitectureDataForArchitectureIllustPage = (data) => {
  if (data.length == 0) {
    return {};
  }
  data = data[0];
  return {
    ...data,
    title:
      data.bldg_name !== ""
        ? data.bldg_name
        : data.name_alt[0] ??
          (data.address.is_exact ? data.address.road : data.address.normal) +
            " " +
            data.use[0],
    use: data.use.map((e) => mapUse(e)),
  };
};

export const convertArchitectureDataForMaterialIllustPage =
  convertArchitectureDataForMapPage;

export const convertArchitectureDataForMaterialBoardPage =
  convertArchitectureDataForMapPage;

const _architecture_scheme = {
  architecture_id: "string",
  location: { type: "Point", coordinates: ["Number", "Number"] },
  architect: ["string"],
  url: {
    brand: "string",
    architect: "string",
  },
  used_materials: ["string"],
  name_alt: ["string"],
  use: ["string"],
  address: {
    is_exact: "Boolean",
    normal: "string",
    road: "string",
    land: "string",
  },
  bldg_name: "string",
  image: "string",
  like_num: "Number",
};

const _converted_data_scheme = {
  architecture_id: "string",
  title: "string",
  illust: "string",
  illust2: "string",
  url: {
    brand: "string",
    architect: "string",
  },
  address: {
    is_exact: "Boolean",
    normal: "string",
    road: "string",
    land: "string",
  },
  architect: ["string"],
  used_materials: ["string"],
  name_alt: ["string"],
  use: ["string"],
  src: "string",
  url: {
    brand: "string",
    architect: "string",
  },
  lat: "Number",
  lng: "Number",
  like_num: "number",
};
