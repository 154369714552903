import React, {
  useEffect,
  useReducer,
  useState,
  useMemo,
  Suspense,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./ItemList.module.scss";
import List from "../atoms/List";
import { _transformScroll } from "../util/alias";
import ImageCard from "./ImageCard";
import useGlobalVar from "../hooks/useGlobalVar";
import { Link } from "react-router-dom";
import DetailCard from "./DetailCard";
import ScrollContainer from "react-indiana-drag-scroll";
import { motion } from "framer-motion";
import { item_num } from "../functions/handleMaterialData";
import useGlobalData from "../hooks/useGlobalData";

const cx = classNames.bind(styles);

const ItemList = ({
  item_list = [],
  type = "card",
  no_searched_data_alert,
  no_data_alert,
  no_data_link,
  loading,
}) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const item_width = useMemo(() => {
    switch (type) {
      case "card":
        const width = Math.min(1024 + 33, global_var.window_width);
        return width > 800
          ? (width - 33) / 4 - (8 * 3) / 4
          : width > 600
          ? (width - 33) / 3 - (8 * 2) / 3
          : width > 400
          ? (width - 33) / 2 - (8 * 1) / 2
          : width - 33;
      case "image":
        return (
          global_var.window_width / Math.ceil(global_var.window_width / 200)
        );
      default:
    }
  }, [global_var.window_width, type]);

  return item_list.length == 0 && !loading ? (
    <motion.div
      className={cx("text-alert")}
      initial={{ opacity: 0.3 }}
      animate={{ opacity: 0.7 }}
      transition={{
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut",
        duration: 1.5,
      }}
    >
      {global_data.search_data?.filter?.current?.length > 0
        ? no_searched_data_alert ?? no_data_alert
        : no_data_alert}
    </motion.div>
  ) : type == "detail" ? (
    <ScrollContainer
      horizontal
      vertical={false}
      className={cx("wrapper", "x-scroll")}
      hideScrollbars
    >
      <List type="row">
        <div className={cx("frame-space")}></div>
        {loading ? (
          <List type="column" attach="left" align="left" gap={0.25}>
            {Array(item_num)
              .fill(0)
              .map((item, idx) => (
                <DetailCard
                  collection="material"
                  width={item_width}
                  type={type}
                  loading
                  key={idx}
                  // delay={0.1 * (idx % 3)}
                ></DetailCard>
              ))}
          </List>
        ) : (
          <Suspense
            fallback={
              <List type="column" attach="left" align="left" gap={0.25}>
                {item_list.map((item, idx) => (
                  <DetailCard
                    {...item}
                    collection="material"
                    type={type}
                    loading
                    key={idx}
                    // delay={0.1 * (idx % 3)}
                  ></DetailCard>
                ))}
              </List>
            }
          >
            <List type="column" attach="left" align="left" gap={0.25}>
              {item_list.map((item, idx) => (
                <Link
                  to={"/material/" + encodeURIComponent(item.id)}
                  key={idx}
                  style={type == "detail" ? {} : {}}
                >
                  <DetailCard
                    {...item}
                    collection="material"
                    type={type}
                  ></DetailCard>
                </Link>
              ))}
            </List>
          </Suspense>
        )}
        <div className={cx("frame-space")}></div>
      </List>
    </ScrollContainer>
  ) : (
    <div className={cx("wrapper", type == "image" ? "full" : "")}>
      <div className={cx("frame", type == "image" ? "full" : "")}>
        {loading ? (
          <List
            type="row"
            multiple_line
            attach="left"
            align="left"
            fillX
            gap={type == "card" ? 0.5 : 0}
          >
            {Array(item_num)
              .fill(0)
              .map((item, idx) => (
                <ImageCard
                  collection="material"
                  width={item_width}
                  type={type}
                  loading
                  key={idx}
                  // delay={0.1 * (idx % 3)}
                ></ImageCard>
              ))}
          </List>
        ) : (
          <Suspense
            fallback={
              <List
                type="row"
                multiple_line
                attach="left"
                align="left"
                fillX
                gap={type == "card" ? 0.5 : 0}
              >
                {item_list.map((item, idx) => (
                  <ImageCard
                    collection="material"
                    key={idx}
                    {...item}
                    width={item_width}
                    type={type}
                    loading
                    // delay={0.1 * (idx % 3)}
                  ></ImageCard>
                ))}
              </List>
            }
          >
            <List
              type="row"
              multiple_line
              attach="left"
              align="left"
              fillX
              gap={type == "card" ? 0.5 : 0}
            >
              {item_list.map((item, idx) => (
                <Link
                  to={"/material/" + encodeURIComponent(item.id)}
                  key={idx}
                  style={type == "detail" ? {} : {}}
                >
                  <ImageCard
                    {...item}
                    collection="material"
                    width={item_width}
                    type={type}
                  ></ImageCard>
                </Link>
              ))}
            </List>
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default ItemList;
