import React, { useEffect, useReducer, useState, useRef, useMemo } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./AlertPopupScienario.module.scss";
import List from "../atoms/List";
import useGlobalData from "../hooks/useGlobalData";
import { _deepcopy, _isValidEmail, _transformScroll } from "../util/alias";
import Chip from "../atoms/Chip";
import useGlobalVar from "../hooks/useGlobalVar";
import DropDown from "../atoms/DropDown";
import Search from "../atoms/Search";
import { filter_data } from "../functions/filterData";
import Popup from "./Popup";
import Button from "../atoms/Button";
import { MATERIAL_TYPE, LOCATION_TYPE, SPEC_TYPE } from "../functions/types";
import axios from "axios";
import { API_URI } from "../shortcut";
import AutoFocusTextarea from "./AutoFocusTextarea";

const cx = classNames.bind(styles);

const SendEMailPopup = ({ popup_data, setPopupData = () => {} }) => {
  const [global_var, setGlobalVar] = useGlobalVar();

  const email_ref = useRef();

  const again = useMemo(() => !!global_var.my_email, [global_var.my_email]);

  const handleSendEmail = (
    user_id,
    prev_email,
    popup_data,
    email_ref,
    setGlobalVar,
    setPopupData
  ) => {
    const value = email_ref.current?.value;
    const currentUrl = window.location.href;
    if (_isValidEmail(value)) {
      axios
        .post(API_URI + "api/saveEmailData", {
          sendId: user_id,
          sendPrevEmail: prev_email,
          sendEmail: value,
          sendData: popup_data.data,
          currentUrl: currentUrl,
        })
        .then(() => {
          setGlobalVar({ my_email: value });
          setPopupData((data) => ({ ...data, id: "email_sent" }));
          setTimeout(() => {
            setPopupData({ id: null });
          }, 1500);
        })
        .catch((err) => {
          setPopupData({ id: "send_error" });
          setTimeout(() => {
            setPopupData((data) => ({
              ...data,
              id: "send_email",
              value,
            }));
          }, 1500);
        });
    } else {
      setPopupData({ id: "invalid_email" });
      setTimeout(
        () =>
          setPopupData((data) => ({
            ...data,
            id: "send_email",
            value,
          })),
        1500
      );
    }
  };

  return (
    <div className={cx("frame-popup")}>
      <List align="center" gap={1} fillX>
        <div className={cx("text-popup-title")}>
          {again ? "메일 다시 등록하기" : "서비스 준비중이예요."}
        </div>

        <List align="center" fillX>
          {again || (
            <div className={cx("text-popup")}>
              지금은 베타 테스트 기간으로, 해당 서비스는 아직 준비중이예요.
            </div>
          )}
          <div className={cx("text-popup")}>
            서비스 메일 주소를 등록해주시면 정식 서비스 론칭 시 연락드릴게요!
          </div>
        </List>
        <AutoFocusTextarea
          className={cx("frame-popup-textarea")}
          rows="1"
          placeholder="메일 주소 입력하기"
          ref={email_ref}
          defaultValue={popup_data.value ?? ""}
        />
        <List attach="space" type="row" fillX gap={0.5} multiple_line>
          {again ? (
            <List type="row" gap={0.5} multiple_line>
              <Button
                type="green-light"
                onClick={() => {
                  setPopupData({ id: null });
                }}
              >
                닫기
              </Button>
              <Button
                type="green-light"
                onClick={() => {
                  handleSendEmail(
                    global_var.id,
                    null,
                    popup_data,
                    email_ref,
                    setGlobalVar,
                    setPopupData
                  );
                }}
              >
                기존 주소에 추가로 등록하기
              </Button>
            </List>
          ) : (
            <Button
              type="green-light"
              onClick={() => {
                setPopupData({ id: null });
              }}
            >
              등록하지 않을래요
            </Button>
          )}
          <Button
            type="brown-light"
            onClick={() => {
              handleSendEmail(
                global_var.id,
                global_var.my_email,
                popup_data,
                email_ref,
                setGlobalVar,
                setPopupData
              );
            }}
          >
            {again ? "변경된 이메일로 등록하기" : "이메일 등록하기"}
          </Button>
        </List>
      </List>
    </div>
  );
};

const AlertPopupScienario = ({ popup_data = {}, setPopupData = () => {} }) => {
  const [global_var, setGlobalVar] = useGlobalVar();

  useEffect(() => {
    if (popup_data.id === undefined && popup_data.data) {
      setPopupData((data) => ({
        ...data,
        id: global_var.my_email ? "already_sent_email" : "send_email",
      }));
    }
  }, [popup_data.id, popup_data.data]);

  return (
    <Popup
      change_trigger={popup_data.id}
      use_backdrop
      onClose={() => {
        setPopupData({ id: null });
      }}
    >
      {popup_data.id === "send_email" ? (
        <SendEMailPopup popup_data={popup_data} setPopupData={setPopupData} />
      ) : popup_data.id == "email_sent" ? (
        <div className={cx("frame-popup")}>
          <div className={cx("text-popup")}>
            성공적으로 메일이 등록되었어요.
          </div>
          <div className={cx("text-popup")}>
            정식 서비스 론칭 시 해당 메일 주소로 연락드릴게요!
          </div>
        </div>
      ) : popup_data.id == "already_sent_email" ? (
        <div className={cx("frame-popup")}>
          <List align="center" gap={1} fillX>
            <div className={cx("text-popup-title")}>서비스 준비중이예요.</div>
            <List align="center" fillX>
              <div className={cx("text-popup")}>
                지금은 베타 테스트 기간으로, {popup_data.data} 서비스는 아직
                준비중이예요.
              </div>
              <div className={cx("text-popup")}>
                정식 서비스 론칭 시 등록해주신 메일 주소로 연락드릴게요!
              </div>
            </List>
            <List attach="center" type="row" fillX gap={0.5} multiple_line>
              <Button
                type="green-light"
                onClick={() => {
                  setPopupData({ id: null });
                }}
              >
                네 알겠어요.
              </Button>
              <Button
                type="brown-light"
                onClick={() => {
                  setPopupData((data) => ({ ...data, id: "send_email" }));
                }}
              >
                메일 새로 등록하기
              </Button>
            </List>
          </List>
        </div>
      ) : popup_data.id == "invalid_email" ? (
        <div className={cx("frame-popup")}>
          <div className={cx("text-popup")}>알맞는 메일 주소가 아니예요.</div>
          <div className={cx("text-popup")}>다시 한번 입력 부탁드려요.</div>
        </div>
      ) : popup_data.id == "send_error" ? (
        <div className={cx("frame-popup")}>
          <div className={cx("text-popup")}>
            서버 오류로 메일을 등록하지 못했어요.
          </div>
          <div className={cx("text-popup")}>다시 한번 등록 부탁드려요.</div>
        </div>
      ) : undefined}
    </Popup>
  );
};

export default AlertPopupScienario;
