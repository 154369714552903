import { Link } from "react-router-dom";

const CustomLink = ({ to, onClick, style, className, children }) => {
  return to ? (
    onClick ? (
      <Link to={to} style={style} className={className}>
        <div onClick={onClick}>{children}</div>
      </Link>
    ) : (
      <Link to={to} style={style} className={className}>
        {children}
      </Link>
    )
  ) : (
    <div onClick={onClick} style={style} className={className}>
      {children}
    </div>
  );
};

export default CustomLink;
