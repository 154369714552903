import { MATERIAL_TYPE, BRAND_TYPE } from "./types";
import { getSortQuery } from "./filterData";

export const getBrandPageQueryOfBrandRecommendData = (
  filter,
  sort_type,
  search_text = ""
) => ({
  collection: "brand_recommend",
  query: [
    ...(filter?.type?.length > 0
      ? [{ $match: { material_type: { $in: filter?.type } } }]
      : []),
    ...(search_text.replace(" ", "").length > 0
      ? [
          {
            $match: {
              $or: search_text.split(" ").map((txt) => ({
                search_text: { $regex: "(?i)" + txt + "(?-i)" },
              })),
            },
          },
        ]
      : []),
    ...getSortQuery(sort_type, "brand_recommend"),
  ],
});

export const convertBrandRecommendDataForBrandPage = (data) => {
  return data;
};

const _brand_recommend_scheme = {
  brand_id: "string",
  name: "string",
  brand_type: BRAND_TYPE,
  material_type: [MATERIAL_TYPE],
  detail_type: ["string"],
  specialty: "string",
};

const _converted_data_scheme = {
  brand_id: "string",
  brand_type: "string",
  material_type: [MATERIAL_TYPE],
  detail_type: ["string"],
  specialty: "string",
};
