import React, {
  useEffect,
  useReducer,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./ArchitectureIllustPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";
import Button from "../atoms/Button";
import LikeAutomatedById from "../component/LikeAutomatedById";
import useQueryParam from "../hooks/useQueryParam";
import {
  convertArchitectureDataForArchitectureIllustPage,
  getArchitectureIllustPageQueryOfArchitectureData,
} from "../functions/handleArchitectureData";
import {
  convertMaterialDataForArchitectureIllustPage,
  getArchitectureIllustPageCountQueryOfMaterialData,
  getArchitectureIllustPageQueryOfMaterialData,
  item_num,
} from "../functions/handleMaterialData";
import axios from "axios";
import { API_URI } from "../shortcut";
import Chip from "../atoms/Chip";
import PageNoList from "../component/PageNoList";
import { ipad_width } from "../util/style";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const ArchitectureIllustPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [architecture_data, setArchitectureData] = useState({});
  const [item_list, setItemList] = useState([]);
  const [item_count, setItemCount] = useState(0);
  const [page_no, setPageNo] = useState(1);

  const query = useQueryParam();

  useLayoutEffect(() => {
    axios
      .put(
        API_URI + "data",
        getArchitectureIllustPageQueryOfArchitectureData(match?.params?.id)
      )
      .then((res) => {
        setArchitectureData(
          convertArchitectureDataForArchitectureIllustPage(res.data)
        );
      })
      .catch(console.log);
  }, [match]);

  useLayoutEffect(() => {
    if (architecture_data.architecture_id) {
      axios
        .put(
          API_URI + "data",
          getArchitectureIllustPageQueryOfMaterialData(
            architecture_data.used_materials?.map((e) => e.id),
            global_data.filter?.current,
            [],
            global_var.data_sort,
            page_no,
            item_num,
            global_data.search_data?.filter?.current ||
              (query.get("search") ?? "")
          )
        )
        .then((res) =>
          setItemList(convertMaterialDataForArchitectureIllustPage(res.data))
        )
        .catch(console.log);
    }
    axios
      .put(
        API_URI + "data",
        getArchitectureIllustPageCountQueryOfMaterialData(
          architecture_data.used_materials?.map((e) => e.id),
          global_data.filter?.current,
          global_var.data_show,
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) => setItemCount(res.data?.[0]?.totalCount))
      .catch(console.log);
  }, [
    architecture_data,
    global_data.filter,
    global_var.data_show,
    global_var.data_sort,
    page_no,
    global_data.search_data,
  ]);
  return (
    <>
      <MetaData path="/map" />
      <Header emph="map" />
      <div className={cx("wrapper")}>
        <List gap={0}>
          <div className={cx("body")}>
            <List gap={0} tight={false}>
              <List gap={0} tight={false}>
                <div className={cx("frame-title")}>
                  <List
                    type={
                      global_var.window_width < ipad_width ? "column" : "row"
                    }
                    align={
                      global_var.window_width < ipad_width ? "left" : "center"
                    }
                    attach="space"
                    gap={1}
                  >
                    <List gap={0.25} align="left">
                      <List gap={0} align="left">
                        <div className={cx("text-title")}>
                          {architecture_data.title}
                        </div>

                        <div className={cx("text-illust")}>
                          건축사사무소 :{" "}
                          {architecture_data.architect?.join(" · ")}
                        </div>
                        <div className={cx("text-illust")}>
                          건축물 위치 :{" "}
                          {architecture_data.address?.is_exact
                            ? architecture_data.address?.road
                            : architecture_data.address?.normal +
                              " (위치 부정확)"}
                        </div>
                      </List>
                      <List
                        type="row"
                        align="center"
                        gap={0.375}
                        tight={false}
                        multiple_line
                      >
                        {architecture_data.name_alt?.map((e, idx) => (
                          <Chip clicked key={idx}>
                            {e}
                          </Chip>
                        ))}
                        {architecture_data.use?.map((e, idx) => (
                          <Chip clicked key={idx}>
                            {e}
                          </Chip>
                        ))}
                      </List>
                    </List>
                    <List
                      type={
                        global_var.window_width < ipad_width
                          ? "row-reverse"
                          : "row"
                      }
                      align="center"
                      attach="end"
                      gap={1}
                      multiple_line
                    >
                      <LikeAutomatedById
                        like_num={architecture_data.like_num}
                        collection="architecture"
                        id={architecture_data.architecture_id}
                        show_num
                      />
                      {architecture_data.url?.brand !== "" && (
                        <Button
                          color="brown-light"
                          onClick={() =>
                            window.open(
                              architecture_data.url?.brand,
                              architecture_data.url?.brand
                            )
                          }
                          GTM={"ArchitecutureIllustPage_브랜드_방문"}
                        >
                          {global_var.window_width < ipad_width
                            ? "브랜드 방문"
                            : "브랜드 방문하기"}
                        </Button>
                      )}
                      {architecture_data.url?.architect !== "" && (
                        <Button
                          color="brown-light"
                          onClick={() =>
                            window.open(
                              architecture_data.url?.architect,
                              architecture_data.url?.architect
                            )
                          }
                          GTM={"ArchitecutureIllustPage_사무소_방문"}
                        >
                          {global_var.window_width < ipad_width
                            ? "사무소 방문"
                            : "건축사사무소 방문하기"}
                        </Button>
                      )}
                      {architecture_data.url?.others?.map((e) => (
                        <Button
                          color="brown-light"
                          onClick={() => window.open(e?.url, e?.url)}
                          GTM={"ArchitecutureIllustPage_기타_방문"}
                        >
                          {e.name}
                        </Button>
                      ))}
                    </List>
                  </List>
                </div>
                <div className={cx("frame-section")}>
                  {architecture_data.title}에 사용된 건축자재 살펴보기
                </div>
                <Filter
                  onSearch={(e) => {
                    window.history.replaceState(
                      {},
                      "",
                      "/architecture/" + match?.params?.id + "?search=" + e
                    );
                  }}
                />
                <ItemList
                  type={global_var.data_show}
                  item_list={item_list}
                  no_data_alert="이 건축물에 사용된 자재 데이터가 모여있지 않아요. 다른 자재유형을 선택하거나 사이트로 직접 방문해보세요."
                  no_searched_data_alert="검색 결과가 없어요. 다시 한번 검색해보세요."
                />
                <div className={cx("frame-space")}></div>
                <PageNoList
                  item_count={item_count}
                  item_num={item_num}
                  onClick={(e) => setPageNo(e)}
                ></PageNoList>
                <div className={cx("frame-space")}></div>
                <div className={cx("frame-space")}></div>
                <Button
                  type="brown-light"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  맨 위로 올라가기
                </Button>
                <div className={cx("frame-space")}></div>
                <div className={cx("frame-space")}></div>
              </List>
            </List>
          </div>
        </List>
      </div>
    </>
  );
};

export default ArchitectureIllustPage;
