import React, { useState, useMemo } from "react";
// { useEffect }

import styles from "./ImageCard.module.scss";
import classNames from "classnames/bind";
import List from "../atoms/List";
import { _formatSuperAndSubscript, _transformScroll } from "../util/alias";
import useGlobalVar from "../hooks/useGlobalVar";
import useGlobalData from "../hooks/useGlobalData";
import LoadedImage from "../atoms/LoadedImage";
import { color, motion } from "framer-motion";
import ScrollContainer from "react-indiana-drag-scroll";
import LikeAutomatedById from "./LikeAutomatedById";

const cx = classNames.bind(styles);

const ImageCard = ({
  id,
  collection = "material",
  src = "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  color_src_list = [
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  ],
  title = "title",
  illust = "illust",
  like = false,
  like_num = 0,
  chips = [],
  width = 200,
  type = "card",
  loading = false,
  have_2d = true,
  have_3d = true,
  have_texture = true,
  filter = {},
  delay = 0,
  onClick,
}) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();

  const [mouse_over, setMouseOver] = useState(false);

  const ratio = useMemo(() => (type == "card" ? 0.7 : 1), [type]);

  return (
    <div
      className={cx("wrapper", type)}
      style={{ width: Math.floor(width - (type == "card" ? 8 : 2)) + "px" }}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={onClick}
    >
      <div
        className={cx("frame-image", type)}
        style={{
          height: Math.floor((width - (type == "card" ? 8 : 2)) * ratio) + "px",
        }}
      >
        {loading ? (
          <motion.div
            className={cx("frame-loading")}
            initial={{ opacity: 0.3 }}
            animate={{ opacity: 0.7 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
              duration: 0.8,
              delay: delay,
            }}
            key="loading"
          ></motion.div>
        ) : (
          <div key="content">
            <LoadedImage
              className={cx(
                "image",
                type == "image" && mouse_over ? "hover" : ""
              )}
              src={src}
              width={Math.floor(width - (type == "card" ? 8 : 2)) + "px"}
              height={
                Math.floor((width - (type == "card" ? 8 : 2)) * ratio) + "px"
              }
              onError={(e) => (e.currentTarget.src = "/img/icon_noimage.png")}
              duration={0.3}
              delay={0.3}
            ></LoadedImage>
            <div className={cx("frame-overlay", type)}>
              <List align="left" attach="space" tight={false}>
                <List
                  padding={0.5}
                  type="row"
                  attach="end"
                  gap={0.25}
                  tight={false}
                >
                  {have_2d ? (
                    <div className={cx("frame-overlay-have", type)}>
                      {/* <Icon2D /> */}
                      <List align="center" attach="center" tight={false}>
                        2D
                      </List>
                    </div>
                  ) : null}
                  {have_texture ? (
                    <div className={cx("frame-overlay-have", type)}>
                      {/* <IconMaterial /> */}
                      <List align="center" attach="center" tight={false}>
                        Tx
                      </List>
                    </div>
                  ) : null}
                  {have_3d ? (
                    <div className={cx("frame-overlay-have", type)}>
                      {/* <Icon3D /> */}
                      <List align="center" attach="center" tight={false}>
                        3D
                      </List>
                    </div>
                  ) : null}
                  {filter?.type ? (
                    <div className={cx("frame-overlay-type")}>
                      {/* <Icon3D /> */}
                      <List align="center" attach="center" tight={false}>
                        {filter?.type}
                      </List>
                    </div>
                  ) : null}
                </List>
                <div
                  className={cx("x-scroll")}
                  onClick={(e) => e.preventDefault()}
                >
                  <ScrollContainer horizontal vertical={false} hideScrollbars>
                    <List type="row" gap={0.25} tight={false}>
                      {color_src_list.map((color_src, idx) => (
                        <div
                          className={cx("frame-overlay-color", type)}
                          key={idx}
                        >
                          <LoadedImage
                            className={cx("image-color")}
                            src={color_src}
                            width={"24px"}
                            height={"24px"}
                            onError={(e) =>
                              (e.currentTarget.src = "/img/icon_material.png")
                            }
                            duration={0.3}
                            delay={0.3}
                          ></LoadedImage>
                        </div>
                      ))}
                    </List>
                  </ScrollContainer>
                </div>
              </List>
            </div>
          </div>
        )}

        {mouse_over && type == "image" ? (
          <div className={cx("frame-hover", type)}>
            <List align="left" attach="space" tight={false}>
              <List type="row" align="left" attach="space">
                {[]}
              </List>
              <List type="row" align="left" attach="space" tight={false}>
                <div className={cx("frame-text")}>
                  <List align="left" gap={0.125}>
                    <div className={cx("text-title")}>{title}</div>
                    <div className={cx("frame-chip")}>
                      <List type="row" align="left" gap={0.375}>
                        <div className={cx("text-illust")}>
                          {_formatSuperAndSubscript(illust)}
                        </div>
                        {/* {chips.map((e) => (
                          <Chip clicked>{e}</Chip>
                        ))} */}
                      </List>
                    </div>
                  </List>
                </div>
                <LikeAutomatedById
                  like_num={like_num}
                  collection={collection}
                  id={id}
                  show_num
                />
              </List>
            </List>
          </div>
        ) : (
          <></>
        )}
      </div>
      {type == "card" ? (
        <List type="row" align="left" attach="space">
          <div className={cx("frame-text")}>
            {loading ? (
              <List align="left" gap={0.125}>
                <div className={cx("text-title", "loading")} />
                <div className={cx("frame-chip", "loading")} />
              </List>
            ) : (
              <List align="left" gap={0.125}>
                <div className={cx("text-title")}>{title}</div>
                <div className={cx("frame-chip")}>
                  <List type="row" align="left" gap={0.375}>
                    <div className={cx("text-illust")}>
                      {_formatSuperAndSubscript(illust)}
                    </div>
                    {/* {chips.map((e) => (
                    <Chip clicked>{e}</Chip>
                  ))} */}
                  </List>
                </div>
              </List>
            )}
          </div>
          <LikeAutomatedById
            like_num={like_num}
            collection={collection}
            id={id}
            show_num
          />
        </List>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ImageCard;

// ### Card

// - shape: default / rectangle
// - children: any
// - padding: int
// - clickable: boolean
// - transparent: boolean
// - onClick: ()=>any
// - use_tooltip: boolean
// - tooltip: [any]
// - tight: boolean
