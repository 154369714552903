import React, { useEffect, useReducer, useState, useRef } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./PrivacyPolicy.module.scss";
import List from "../atoms/List";
import Popup from "../component/Popup";
import Button from "../atoms/Button";
import useGlobalData from "../hooks/useGlobalData";

const cx = classNames.bind(styles);

const PrivacyPolicy = ({}) => {
  const [global_data, setGlobalData] = useGlobalData();

  return (
    <Popup use_backdrop onClose={() => setGlobalData({ popup_data: null })}>
      {global_data.popup_data?.id === "privacy_policy" && (
        <div className={cx("frame-popup")}>
          <List align="center" gap={1} fillX>
            <div className={cx("text-popup")}>
              <h1>개인정보 처리 방침</h1>
              <p>
                <strong>"머티리얼플랫폼"</strong>('이하 "사이트")은(는) 개인정보
                보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한
                고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과
                같이 개인정보 처리 방침을 수립·공개합니다.
              </p>

              <h2>제1조 (개인정보의 처리 목적)</h2>
              <p>
                사이트는 다음의 목적을 위하여 개인정보를 처리합니다. 처리한
                개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용
                목적이 변경될 시에는 사전 동의를 구할 예정입니다.
              </p>
              <ol>
                <li>
                  <strong>이메일 등록 및 관리</strong>
                  <br />
                  사용자가 직접 등록하는 경우에 한해 이메일 주소를 수집하여 관련
                  서비스를 제공하고, 사용자와의 소통을 위해 사용합니다.
                </li>
                {/* <li>
                  <strong>웹사이트 이용 통계</strong>
                  <br />
                  사용자의 IP 주소를 수집하여 웹사이트 방문 통계와 보안 관리를
                  위해 사용합니다.
                </li> */}
              </ol>

              <h2>제2조 (개인정보의 처리 및 보유 기간)</h2>
              <ol>
                <li>
                  사이트는 법령에 따른 개인정보 보유·이용기간 또는
                  정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
                  보유·이용기간 내에서 개인정보를 처리·보유합니다.
                </li>
                <li>
                  각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                  <ul>
                    <li>
                      <strong>이메일 주소:</strong> 사용자가 삭제를 요청하거나,
                      서비스 제공 목적이 달성될 때까지.
                    </li>
                    {/* <li>
                      <strong>IP 주소:</strong> 수집일로부터 1년간 보유 후 파기.
                    </li> */}
                  </ul>
                </li>
              </ol>

              <h2>제3조 (개인정보의 제3자 제공)</h2>
              <p>
                사이트는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서
                명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한
                규정 등이 있는 경우에만 개인정보를 제3자에게 제공합니다.
              </p>

              <h2>제4조 (개인정보처리의 위탁)</h2>
              <p>
                사이트는 원활한 개인정보 업무처리를 위하여 개인정보 처리업무를
                위탁하지 않습니다.
              </p>

              <h2>제5조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)</h2>
              <p>
                정보주체는 사이트에 대해 언제든지 다음 각 호의 개인정보 보호
                관련 권리를 행사할 수 있습니다.
              </p>
              <ol>
                <li>개인정보 열람요구</li>
                <li>오류 등이 있을 경우 정정 요구</li>
                <li>삭제요구</li>
                <li>처리정지 요구</li>
              </ol>

              <h2>제6조 (처리하는 개인정보의 항목 작성)</h2>
              <p>사이트는 다음의 개인정보 항목을 처리하고 있습니다.</p>
              <ul>
                {/* <li>
                  <strong>필수항목:</strong> IP 주소
                </li> */}
                <li>
                  <strong>선택항목:</strong> 이메일 주소 (사용자가 자발적으로
                  등록하는 경우)
                </li>
              </ul>

              <h2>제7조 (개인정보의 파기)</h2>
              <p>
                사이트는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이
                해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과
                같습니다.
              </p>
              <ul>
                <li>
                  <strong>파기절차:</strong> 정보주체가 입력한 정보는 목적 달성
                  후 별도의 DB에 옮겨져 (종이의 경우 별도의 서류) 내부 방침 및
                  기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다.
                  이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는
                  다른 목적으로 이용되지 않습니다.
                </li>
                <li>
                  <strong>파기기한:</strong> 정보주체의 개인정보는 개인정보의
                  보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에,
                  개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등
                  그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가
                  불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를
                  파기합니다.
                </li>
              </ul>

              <h2>제8조 (개인정보 보호책임자)</h2>
              <p>
                사이트는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
                처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
                같이 개인정보 보호책임자를 지정하고 있습니다.
              </p>
              <ul>
                <li>
                  <strong>개인정보 보호책임자:</strong> 사이트 운영자
                </li>
                <li>
                  <strong>연락처:</strong> team.materialplatform@gmail.com
                </li>
              </ul>
              <p>
                정보주체께서는 사이트의 서비스(또는 사업)을 이용하시면서 발생한
                모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한
                사항을 개인정보 보호책임자에게 이메일로 문의하실 수 있습니다.
                사이트는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴
                것입니다.
              </p>

              <h2>제9조 (개인정보 처리방침 변경)</h2>
              <p>
                이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에
                따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의
                시행 7일 전부터 변경된 처리방침 공개를 통하여 고지할 것입니다.
              </p>
              <ul>
                <li>
                  <strong>공고일자:</strong> 2024년 7월 31일
                </li>
                <li>
                  <strong>시행일자:</strong> 2024년 8월 7일
                </li>
              </ul>
            </div>
            <Button
              color="brown-light"
              onClick={() => setGlobalData({ popup_data: null })}
            >
              닫기
            </Button>
          </List>
        </div>
      )}
    </Popup>
  );
};

export default PrivacyPolicy;
