import React, { useState, useEffect, useRef, useMemo, Suspense } from "react";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { _formatUrl } from "../util/alias";
// { useEffect }

const LoadedImage = ({
  className,
  src = "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  width,
  height,
  onError = () => {},
  duration = 0,
  delay = 0,
  onClick = () => {},
}) => {
  const loadImage = async (src) =>
    await new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        res(src);
      };
      img.onerror = (e) => {
        console.log(e);
        res(src);
      };
    });

  const { data } = useQuery({
    queryKey: [_formatUrl(src)],
    queryFn: () => loadImage(_formatUrl(src)),
    suspense: true,
  });

  useEffect(() => {
    // console.log(data);
  }, [data]);
  return (
    <motion.img
      className={className}
      src={data}
      width={width}
      height={height}
      onError={onError}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: duration,
        delay: delay,
      }}
      onClick={onClick}
      style={{ pointerEvents: "none" }}
    ></motion.img>
  );
};

export default LoadedImage;
