import React, { useState, useMemo } from "react";
// { useEffect }

import styles from "./DetailCard.module.scss";
import classNames from "classnames/bind";
import List from "../atoms/List";
import { _formatSuperAndSubscript, _transformScroll } from "../util/alias";
import useGlobalVar from "../hooks/useGlobalVar";
import useGlobalData from "../hooks/useGlobalData";
import LoadedImage from "../atoms/LoadedImage";
import LikeAutomatedById from "./LikeAutomatedById";
import { color, motion } from "framer-motion";

const cx = classNames.bind(styles);

const DetailCard = ({
  src = "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  color_src_list = [
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
    "https://helpx.adobe.com/content/dam/help/en/photoshop/using/quick-actions/remove-background-before-qa1.png",
  ],
  id,
  title = "title",
  illust = "illust",
  like = false,
  like_num = 0,
  chips = [],
  width = 200,
  type = "card",
  loading = false,
  have_2d = true,
  have_3d = true,
  filter = {},
  have_texture = true,
  delay = 0,
  onClick,
  brand = { distribution_brand: { name: "brand" } },
  custom_data = [],
  collection = "material",
}) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const data_list = useMemo(
    () => [
      { name: "자재명", value: title, width: 150 },
      { like: true },
      {
        name: "브랜드" + (brand?.distribution_brand?.name ? "(유통사)" : ""),
        value:
          brand?.distribution_brand?.name == ""
            ? brand?.original_brand?.name
            : brand?.distribution_brand?.name,

        width: 100,
      },
      // { name: "요약정보", value: illust, width: 250 },
      {
        name: "2D",
        value: have_2d ? "○" : "✕",
        width: 30,
        center: true,
      },
      {
        name: "3D",
        value: have_3d ? "○" : "✕",
        width: 30,
        center: true,
      },
      {
        name: "텍스쳐",
        value: have_3d ? "○" : "✕",
        width: 40,
        center: true,
      },
      {
        name: "자재유형",
        value: filter?.type ?? "-",
        width: 100,
      },
      {
        name: "세부 종류",
        // value: "",
        color_src_list,
        width: 200,
      },
      ...custom_data.map((e) => ({ ...e, width: 150 })),
    ],
    [title, illust]
  );
  const [mouse_over, setMouseOver] = useState(false);

  return (
    <div className={cx("wrapper", type)} onClick={onClick}>
      <List type="row" gap={0.5}>
        {loading ? (
          <motion.div
            className={cx("frame-loading")}
            initial={{ opacity: 0.3 }}
            animate={{ opacity: 0.7 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
              duration: 0.8,
              delay: delay,
            }}
            key="loading"
          ></motion.div>
        ) : (
          <div className={cx("frame-image", type)}>
            <LoadedImage
              className={cx("image", type)}
              src={src}
              width={type == "small" ? "24px" : "48px"}
              height={type == "small" ? "24px" : "48px"}
              onError={(e) => (e.currentTarget.src = "/img/icon_noimage.png")}
              duration={0.3}
              delay={0.3}
            ></LoadedImage>
          </div>
        )}
        <List type="row" gap={1}>
          {data_list.map((data) =>
            data.like ? (
              <LikeAutomatedById
                like_num={like_num}
                collection={collection}
                id={id}
                show_num
              />
            ) : (
              <List
                align={data.center ? "center" : "left"}
                style={{ width: data.width + "px", flexShrink: 0 }}
              >
                <div
                  className={cx(
                    "text-name",
                    type,
                    data.center ? "center" : "",
                    loading ? "loading" : ""
                  )}
                >
                  {loading ? null : data.name}
                </div>
                <div
                  className={cx(
                    "text-value",
                    type,
                    data.center ? "center" : "",
                    loading ? "loading" : ""
                  )}
                >
                  {loading ? null : data.color_src_list ? (
                    <List
                      type="row"
                      gap={0.25}
                      tight={false}
                      style={{ overflow: "hidden" }}
                    >
                      {color_src_list.slice(0, 7).map((color_src) => (
                        <div className={cx("frame-color", type)}>
                          <LoadedImage
                            className={cx("image-color")}
                            src={color_src}
                            width={"20px"}
                            height={"20px"}
                            onError={(e) =>
                              (e.currentTarget.src = "/img/icon_material.png")
                            }
                            duration={0.3}
                            delay={0.3}
                          ></LoadedImage>
                        </div>
                      ))}
                      {color_src_list?.length > 7 ? "···" : ""}
                      {color_src_list?.length == 0 ? "-" : ""}
                    </List>
                  ) : (
                    _formatSuperAndSubscript(data.value)
                  )}
                </div>
              </List>
            )
          )}
        </List>
      </List>
    </div>
  );
};

export default DetailCard;

// ### Card

// - shape: default / rectangle
// - children: any
// - padding: int
// - clickable: boolean
// - transparent: boolean
// - onClick: ()=>any
// - use_tooltip: boolean
// - tooltip: [any]
// - tight: boolean
